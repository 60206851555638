/*
 *
 * 旧AtoneRegisterパラメータのスキーマ定義
 *
 */
import { z } from 'zod';

/** 旧Atone認証コールバックスキーマ */
export const legacyAtoneAuthenticatedCallbackSchema = z
  .function(
    z.tuple([
      // token
      z.string(),
      // user_no
      z.string().optional(),
    ]),
    z.unknown(),
  )
  .default(() => () => {});
/** 旧Atoneエラーコールバックスキーマ */
export const legacyAtoneAuthErrorCallbackSchema = z
  .function(
    z.tuple([
      // name
      z.string(),
      // message
      z.string(),
      // errors
      z
        .array(
          z.object({
            // エラーコード
            code: z.string(),
            // エラーメッセージ
            messages: z.array(z.string()),
            // パラメータ
            params: z.array(z.string()),
          }),
        )
        .optional(),
    ]),
    z.unknown(),
  )
  .default(() => () => {});
/** 旧Atoneキャンセルコールバックスキーマ */
export const legacyAtoneAuthCancelCallbackSchema = z.function(z.tuple([]), z.unknown()).default(() => () => {});

export const legacyAtoneAuthServiceCallbackClassifier = z.object({
  service_type: z.literal('01'),
  modal_mode: z.literal('02'),
  is_legacy: z.literal(true),
});

export const legacyAtoneAuthCallbackDefinition = {
  /**
   * コールバック実行時にModal側から送られるメッセージに含まれる。
   *
   * 通常のコールバックはサービスに紐づいてる。どのサービスのコールバックを呼ぶか、これを持ってして決める。
   */
  service: { service_type: '01', modal_mode: '02' } as const,
  /**
   * コンフィグパラメータに含まれるコールバックをパースする時に使用する。
   *
   * // TODO: 多分消せる。serviceプロパティでなんとかできるんじゃないかと思う。
   */
  classifier: legacyAtoneAuthServiceCallbackClassifier,
  /**
   * スキーマ本体。
   */
  schema: z.object({
    // 旧Atoneのソース見るとfailedコールバックも実装されてるけど、ソース追いかける限り呼ばれることはないためfailedは用意していない。
    authenticated: legacyAtoneAuthenticatedCallbackSchema,
    cancelled: legacyAtoneAuthCancelCallbackSchema,
    error: legacyAtoneAuthErrorCallbackSchema,
  }),
};
