import { z } from 'zod';

/**
 * モーダルがコールバック実行要求する時のメッセージのメッセージデータスキーマ
 */
export const callbackRequestDataSchema = z
  .object({
    messageId: z.number(),
    type: z.string(),
    payload: z.array(z.any()).default([]),
    // TODO: serviceは必須にすることを考える。
    service: z
      .object({
        service_type: z.string(),
        modal_mode: z.string(),
      })
      .optional(),
  })
  .strict();

/**
 * モーダルがコールバック実行結果を受信する時のメッセージデータスキーマ
 */
export const callbackResponseDataSchema = z.union([
  z.object({
    success: z.literal(true),
    messageId: z.number(),
    type: z.string(),
    payload: z.unknown(),
  }),
  z.object({
    success: z.literal(false),
    messageId: z.number(),
    type: z.string(),
    error: z.object({
      name: z.string(),
      message: z.string(),
      stack: z.string().optional(),
    }),
  }),
]);
