/*
 *
 * 旧Atoneパラメータのスキーマ定義
 *
 */
import { z } from 'zod';

/** 旧atone成功コールバックのスキーマ */
export const legacyAtoneSucceededCallbackSchema = z
  .function(
    z.tuple([
      z
        .object({
          id: z.string(),
          authorization_result: z.number(),
        })
        // 仕様上のインターフェイスとは異なり、実際にはcaptueのレスポンス内容全てが含まれる。
        .passthrough(),
    ]),
    z.unknown(),
  )
  .default(() => () => {});

/** 旧Atone失敗コールバックスキーマ */
export const legacyAtoneFailedCallbackSchema = z
  .function(
    z.tuple([
      z
        .object({
          id: z.string(),
          authorization_result: z.number(),
          authorization_result_ng_reason: z.number(),
        })
        // 仕様上のインターフェイスとは異なり、実際にはcaptueのレスポンス内容全てが含まれる。
        .passthrough()
        .optional(),
    ]),
    z.unknown(),
  )
  .default(() => () => {});

/** 旧Atone認証コールバックスキーマ */
export const legacyAtoneAuthenticatedCallbackSchema = z
  .function(
    z.tuple(
      // 引数
      [
        // 第一引数: token
        z.string(),
        // 第二引数: user_no
        z.string().optional(),
      ],
    ),
    // 戻り値
    z.unknown(),
  )
  .default(() => () => {});

/** 旧Atoneエラーコールバックスキーマ */
export const legacyAtoneErrorCallbackSchema = z
  .function(
    z.tuple([
      // name
      z.string(),
      // message
      z.string(),
      // errors
      z
        .array(
          z.object({
            // エラーコード
            code: z.string(),
            // エラーメッセージ
            messages: z.array(z.string()),
            // パラメータ
            params: z.array(z.string()),
          }),
        )
        .optional(),
    ]),
    z.unknown(),
  )
  .default(() => () => {});

/** 旧Atoneキャンセルコールバックスキーマ */
export const legacyAtoneCancelCallbackSchema = z.function(z.tuple([]), z.unknown()).default(() => () => {});

/** 旧Atoneプリペイメントコールバックスキーマ */
export const legacyAtonePrePaymentCallbackSchema = z
  .function(z.tuple([z.unknown()]), z.union([z.boolean(), z.promise(z.boolean())]))
  .default(() => () => true);

/** 旧AtoneInvalidコールバックスキーマ */
export const legacyAtoneInvalidCallbackSchema = z.function(z.tuple([]), z.unknown()).default(() => () => {});

export const legacyAtoneSettlementServiceCallbackClassifier = z.object({
  service_type: z.literal('01'),
  modal_mode: z.literal('01'),
  is_legacy: z.literal(true),
});

export const legacyAtoneSettlementCallbackDefinition = {
  /**
   * コールバック実行時にModal側から送られるメッセージに含まれる。
   *
   * 通常のコールバックはサービスに紐づいてる。どのサービスのコールバックを呼ぶか、これを持ってして決める。
   * // TODO: is_legacyつけるかどうか検討同じサービスを重複して登録できないから要らないはずではある。
   */
  service: { service_type: '01', modal_mode: '01' } as const,
  /**
   * コンフィグパラメータに含まれるコールバックをパースする時に使用する。
   *
   * // TODO: 多分消せる。serviceプロパティでなんとかできるんじゃないかと思う。
   */
  classifier: legacyAtoneSettlementServiceCallbackClassifier,
  /**
   * スキーマ本体。
   */
  schema: z.object({
    authenticated: legacyAtoneAuthenticatedCallbackSchema,
    succeeded: legacyAtoneSucceededCallbackSchema,
    failed: legacyAtoneFailedCallbackSchema,
    cancelled: legacyAtoneCancelCallbackSchema,
    pre_payment: legacyAtonePrePaymentCallbackSchema,
    invalid: legacyAtoneInvalidCallbackSchema,
    error: legacyAtoneErrorCallbackSchema,
  }),
};
