import { z } from 'zod';
/** エラーコールバックスキーマ */

export const npErrorCallbackSchema = <T extends z.ZodTypeAny, U extends z.ZodTypeAny>(classifier: {
  service_type: T;
  modal_mode: U;
}) =>
  z
    .function(
      z.tuple([
        z.object({
          name: z.string(),
          message: z.string().optional(),
          errors: z
            .array(
              z.object({
                // エラーコード
                code: z.string(),
                // エラーメッセージ
                messages: z.array(z.string()),
                // パラメータ
                params: z.array(z.string()),
              }),
            )
            .optional(),
          service_type: classifier.service_type.optional(),
          modal_mode: classifier.modal_mode.optional(),
        }),
      ]),
      z.unknown(),
    )
    .default(() => () => {});

/** キャンセルコールバック */
export const npCancelCallbackSchema = <T extends z.ZodTypeAny, U extends z.ZodTypeAny>(classifier: {
  service_type: T;
  modal_mode: U;
}) =>
  z
    .function(
      //
      z.tuple([z.object({ service_type: classifier.service_type, modal_mode: classifier.modal_mode })]),
      z.unknown(),
    )
    .default(() => () => {});
