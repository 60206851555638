import { z } from 'zod';
import { atoneAuthenticatedCallbackSchema } from '@schemas/common-schemas/atone-common-schema';
import { npCancelCallbackSchema, npErrorCallbackSchema } from '@schemas/common-schemas/common-callback-schema';

/**
 * 会員サイト側で実装するコールバックの中で、実行されているサービスを特定するための情報。
 */
const atoneLiteAuthServiceCallbackClassifier = z.object({
  service_type: z.literal('04'),
  modal_mode: z.literal('02'),
});

export const atoneLiteAuthCallbackDefinition = {
  /**
   * コールバック実行時にModal側から送られるメッセージに含まれる。
   *
   * 通常のコールバックはサービスに紐づいてる。どのサービスのコールバックを呼ぶか、これを持ってして決める。
   */
  service: { service_type: '04', modal_mode: '02' } as const,
  /**
   * コンフィグパラメータに含まれるコールバックをパースする時に使用する。
   *
   * // TODO: 多分消せる。serviceプロパティでなんとかできるんじゃないかと思う。
   */
  classifier: atoneLiteAuthServiceCallbackClassifier,
  /**
   * スキーマ本体。
   */
  schema: z.object({
    authenticated: atoneAuthenticatedCallbackSchema(atoneLiteAuthServiceCallbackClassifier.shape),
    cancelled: npCancelCallbackSchema(atoneLiteAuthServiceCallbackClassifier.shape),
    error: npErrorCallbackSchema(atoneLiteAuthServiceCallbackClassifier.shape),
  }),
};
