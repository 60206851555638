import ModalDriver from '@np/ModalDriver';
import NpModule from '@np/Np';
import AtoneModule from '@np/Atone';
import AtoneRegisterModule from '@np/AtoneRegister';
import '@berlin-front/common/i18n';

/* eslint-disable no-var, vars-on-top */
declare global {
  /**
   * NP決済・認証サービス
   *
   * Atone、NP後払いの全てのサービスに対応したモジュールです。
   * これ以外のAtone、AtoneRegisterに関しては古いモジュールの互換インターフェイスなので、絶対に新規では使わないこと。
   */
  var Np: typeof NpModule;
  /**
   * 旧Atone互換atone翌月後払い決済用インターフェイス。
   *
   * 旧atone.jsと同様のインターフェイスを提供します。
   * 新規実装、Np後払い、都度後払いを利用したい場合は {@link Np} を使用します。
   * Npモジュールでは `{ service_type: '01', modal_mode: '01' }` のサービスに相当します。
   *
   */
  var Atone: typeof AtoneModule;
  /**
   * 旧AtoneRegister互換atone認証モーダルインターフェイス。
   *
   * 旧Atoneと同じ方法で翌月後払いモード専用の認証モーダルを提供します。
   * 新規実装、Np後払い、都度後払いを利用したい場合は {@link Np} を使用します。
   * Npモジュールでは `{ service_type: '01', modal_mode: '02' }` のサービスに相当します。
   *
   */
  var AtoneRegister: typeof AtoneRegisterModule;
}
/* eslint-enable no-var, vars-on-top */
ModalDriver.env({
  moduleUrl: process.env.NP_JS_MODAL_URL,
});
ModalDriver.loadModal();

const script = document.currentScript as HTMLScriptElement;
const { src } = script;
const globalExports = (script.dataset.npGlobalExports ?? '')
  .split(',')
  .map((v) => v.trim())
  .filter((v) => v);

if (globalExports.includes('Np') || (globalExports.length === 0 && src.endsWith('/np.js'))) {
  window.Np = NpModule;
}
if (globalExports.includes('Atone') || (globalExports.length === 0 && src.endsWith('/atone.js'))) {
  window.Atone = AtoneModule;
}
if (globalExports.includes('AtoneRegister') || (globalExports.length === 0 && src.endsWith('/register.js'))) {
  window.AtoneRegister = AtoneRegisterModule;
}
