import { z } from 'zod';
import {
  atoneAuthenticatedCallbackSchema,
  atoneFailedCallbackSchema,
  atoneInvalidCallbackSchema,
  atonePrePaymentCallbackSchema,
  atoneSucceededCallbackSchema,
} from '@schemas/common-schemas/atone-common-schema';
import { npCancelCallbackSchema, npErrorCallbackSchema } from '@schemas/common-schemas/common-callback-schema';

/**
 * 会員サイト側で実装するコールバックの中で、実行されているサービスを特定するための情報。
 */
const atoneSettlementServiceCallbackClassifier = z.object({
  service_type: z.literal('01'),
  modal_mode: z.literal('01'),
});

export const atoneSettlementCallbackDefinition = {
  /**
   * コールバック実行時にModal側から送られるメッセージに含まれる。
   *
   * 通常のコールバックはサービスに紐づいてる。どのサービスのコールバックを呼ぶか、これを持ってして決める。
   */
  service: { service_type: '01', modal_mode: '01' } as const,
  /**
   * コンフィグパラメータに含まれるコールバックをパースする時に使用する。
   *
   * // TODO: 多分消せる。serviceプロパティでなんとかできるんじゃないかと思う。
   */
  classifier: atoneSettlementServiceCallbackClassifier,
  /**
   * スキーマ本体。
   */
  schema: z.object({
    authenticated: atoneAuthenticatedCallbackSchema(atoneSettlementServiceCallbackClassifier.shape),
    succeeded: atoneSucceededCallbackSchema(atoneSettlementServiceCallbackClassifier.shape),
    failed: atoneFailedCallbackSchema(atoneSettlementServiceCallbackClassifier.shape),
    cancelled: npCancelCallbackSchema(atoneSettlementServiceCallbackClassifier.shape),
    pre_payment: atonePrePaymentCallbackSchema(atoneSettlementServiceCallbackClassifier.shape),
    invalid: atoneInvalidCallbackSchema(atoneSettlementServiceCallbackClassifier.shape),
    error: npErrorCallbackSchema(atoneSettlementServiceCallbackClassifier.shape),
  }),
};
