// TODO: zodベースでスキーマ定義するようになったのでこのファイルは捨てること。

/**
 * モーダル起動パラメータを保持するコンテキスト。
 * 最上層に配置されることで、他の全てのコンテキストから保持データの参照が可能。
 *
 * データ保持のみに特化しており、実際のメッセージのやり取りは、対となるModalBridgeContextが行う。
 * 対となるModalBridgeContextは、各種サービス機能にアクセスする必要があるため、共通コンテキストの最下層に配置される。
 */

import { createContext } from '@berlin-front/common/composables/context';
import { ref, shallowReactive } from '@vue/composition-api';
import { SnakeCasedPropertiesDeep } from 'type-fest';
import {
  AtoneCapturePostRequestSalesFormEnum,
  AtoneCapturePostRequestTransactionOptionsEnum,
  LiteCapturePostRequestSalesFormEnum,
  LiteCapturePostRequestTransactionOptionsEnum,
  AtoneErrorMessage,
  NpAtobaraiRegistTransactionPostRequestAuthoriOptionUserOptionEnum,
  NpAtobaraiRegistTransactionPostRequestCustomerMembershipFlagEnum,
  NpAtobaraiRegistTransactionPostRequestPaymentBillTypeOptionBillTypeEnum,
  NpAtobaraiRegistTransactionPostRequestSalesFormEnum,
  NpAtobaraiRegistTransactionPostRequestTransactionOptionsEnum,
} from '@netprotections/berlin-bff';
import { includes, TypeReplace } from '@berlin-front/common/utils';

export const SERVICE_TYPE = {
  /**
   * Atone翌月払い
   */
  ATONE: '01',
  /**
   * NP後払い
   */
  ATOBARAI: '02',
  /**
   * Atoneつど後払い
   */
  ATONE_LITE: '04',
} as const;

export type ServiceType = (typeof SERVICE_TYPE)[keyof typeof SERVICE_TYPE];

export const MODAL_MODE = {
  /**
   * 決済モーダル
   */
  SETTLEMENT: '01',
  /**
   * 認証モーダル
   */
  AUTH: '02',
} as const;

export type ModalMode = (typeof MODAL_MODE)[keyof typeof MODAL_MODE] | undefined;

/**
 * 審査オプション
 */
export type AuthoriOptions = {
  /**
   * ユーザ種別
   */
  userOption?: NpAtobaraiRegistTransactionPostRequestAuthoriOptionUserOptionEnum;
};

/**
 * 購入者
 */
type AtoneCustomer = {
  /** 氏名 */
  customerName: string;
  /** よみがな氏名 */
  customerNameKana?: string;
  /** 姓 */
  customerFamilyName?: string;
  /** よみがな姓 */
  customerFamilyNameKana?: string;
  /** 名 */
  customerGivenName?: string;
  /** よみがな名 */
  customerGivenNameKana?: string;
  /** 会社名 */
  companyName?: string;
  /** 部署名 */
  department?: string;
  /** 郵便番号 */
  zipCode?: string;
  /** 住所 */
  address?: string;
  /** 電話番号 */
  tel?: string;
  /** メールアドレス */
  email?: string;
  /** 携帯電話番号 */
  phoneNumber?: string;
  /** 生年月日 */
  birthday?: string;
  /** 性別 */
  sexDivision?: 1 | 2 | 3;
  /** 累計購入回数 */
  totalPurchaseCount?: number;
  /** 累計購入金額 */
  totalPurchaseAmount?: number;
  /** 店舗会員ID */
  shopCustomerId?: string;
  /** 会員歴 */
  membershipPeriod?: number;
  /** 本人確認ステータス */
  identificationStatus?: (1 | 2 | 3 | 4 | 9)[];
  /** 過去商材カテゴリ */
  pastMerchandiseCategory?: string[][];
  /** 過去ブランド名 */
  pastBrandName?: string[];
  /** 過去利用決済 */
  pastPaymentWay?: (1 | 2 | 3 | 4 | 5 | 9 | 99)[];
  /** 端末ID */
  terminalId?: string;
  /** 店舗販売者コード */
  shopCustomerSellerCode?: string;
};

/**
 * 購入者
 */
type NpAtobaraiCustomer = {
  /** 氏名 */
  customerName: string;
  /** よみがな氏名 */
  customerNameKana?: string;
  /** 姓 */
  customerFamilyName?: string;
  /** よみがな姓 */
  customerFamilyNameKana?: string;
  /** 名 */
  customerGivenName?: string;
  /** よみがな名 */
  customerGivenNameKana?: string;
  /** 会社名 */
  companyName?: string;
  /** 部署名 */
  department?: string;
  /** 郵便番号 */
  zipCode: string;
  /** 住所 */
  address: string;
  /** 電話番号 */
  tel: string;
  /** メールアドレス */
  email: string;
  /** 携帯電話番号 */
  phoneNumber?: string;
  /** 生年月日 */
  birthday?: string;
  /** 性別 */
  sexDivision?: 1 | 2 | 3;
  /** 最終購入日 */
  lastPurchaseDate?: string;
  /** 最終購入金額 */
  lastPurchaseAmount?: number;
  /** 累計購入回数 */
  totalPurchaseCount?: number;
  /** 累計購入金額 */
  totalPurchaseAmount?: number;
  /** 店舗会員ID */
  shopCustomerId?: string;
  /** 会員登録日 */
  membershipStartDate?: string;
  /** 会員歴 */
  membershipPeriod?: number;
  /** 会員ランク */
  membershipRank?: string;
  /** 本人確認ステータス */
  identificationStatus?: (1 | 2 | 3 | 4 | 9)[];
  /** 店舗会員フラグ */
  membershipFlag?: NpAtobaraiRegistTransactionPostRequestCustomerMembershipFlagEnum;
  /** 過去商材カテゴリ */
  pastMerchandiseCategory?: string[][];
  /** 過去ブランド名 */
  pastBrandName?: string[];
  /** 過去利用決済 */
  pastPaymentWay?: (1 | 2 | 3 | 4 | 5 | 9 | 99)[];
  /** 端末ID */
  terminalId?: string;
};

/**
 * サービス提供先-配送先（Atone）
 */
type AtoneDestCustomers = {
  /** 氏名 */
  destCustomerName: string;
  /** よみがな氏名 */
  destCustomerNameKana?: string;
  /** 会社名 */
  destCompanyName?: string;
  /** 部署名 */
  destDepartment?: string;
  /** 郵便番号 */
  destZipCode: string;
  /** 住所 */
  destAddress: string;
  /** 電話番号 */
  destTel?: string;
};

/**
 * サービス提供先-配送先（NP後払い）
 */
type NpAtobaraiDestCustomers = {
  /** 氏名 */
  destCustomerName: string;
  /** よみがな氏名 */
  destCustomerNameKana?: string;
  /** 会社名 */
  destCompanyName?: string;
  /** 部署名 */
  destDepartment?: string;
  /** 郵便番号 */
  destZipCode: string;
  /** 住所 */
  destAddress: string;
  /** 電話番号 */
  destTel: string;
};

/**
 * 商品情報（Atone）
 */
type AtoneItem = {
  /** 商品名 */
  itemName: string;
  /** 商品単価 */
  itemPrice: number;
  /** 注文数 */
  itemCount: number;
  /** 加盟店商品ID */
  shopItemId: string;
  /** 商品URL */
  itemUrl?: string;
  /** 商材カテゴリ */
  merchandiseCategory?: string[];
  /** ブランド名 */
  brandName?: string;
  /** 販売者コード */
  shopSellerCode?: string;
  /** 販売価格 */
  originalPrice?: number;
  /** 参考価格 */
  listPrice?: number;
};

/**
 * 商品情報（NP後払い）
 */
type NpAtobaraiItem = {
  /** 商品名 */
  itemName: string;
  /** 商品単価 */
  itemPrice: number;
  /** 注文数 */
  itemCount: number;
  /** 加盟店商品ID */
  shopItemId?: string;
  /** 商品URL */
  itemUrl?: string;
  /** 商材カテゴリ */
  merchandiseCategory?: string[];
  /** ブランド名 */
  brandName?: string;
  /** 販売者コード */
  shopSellerCode?: string;
  /** 販売価格 */
  originalPrice?: number;
  /** 参考価格 */
  listPrice?: number;
};

/**
 * Atoneサービス提供者
 */
type AtoneServiceSupplier = {
  /** 店舗会員ID  */
  supplierUserId?: string;
  /** 会員登録日  */
  membershipStartDate?: string;
  /** 会員歴  */
  membershipPeriod?: number;
  /** 本人確認ステータス  */
  identificationStatus?: (1 | 2 | 3 | 4 | 9)[];
  /** 累計販売回数  */
  totalSalesCount?: number;
  /** 累計販売額  */
  totalSalesAmount?: number;
  /** 過去商材カテゴリ  */
  pastMerchandiseCategory?: string[][];
};

type OldAtoneServiceSupplier = {
  /** 店舗会員ID  */
  shopCustomerId?: string;
  /** 会員登録日  */
  membershipStartDate?: string;
  /** 会員歴  */
  membershipPeriod?: number;
  /** 本人確認ステータス  */
  identificationStatus?: (1 | 2 | 3 | 4 | 9)[];
  /** 累計販売回数  */
  totalSalesCount?: number;
  /** 累計販売額  */
  totalSalesAmount?: number;
  /** 過去商材カテゴリ  */
  pastMerchandiseCategory?: string[][];
};

/**
 * Atone決済パラメタ
 */
export type AtonePayment = {
  /**
   * 加盟店取引ID
   */
  shopTransactionNo: string;
  /**
   * チェックサム
   */
  checksum: string;
  /**
   * 取引オプション
   */
  transactionOptions: AtoneCapturePostRequestTransactionOptionsEnum[];
  /**
   * ユーザID
   */
  userNo?: string;
  /**
   * 売り上げ確定
   */
  salesSettled?: boolean;
  /**
   * 加盟店取引備考
   */
  description?: string;
  /**
   * 定期初回のNP取引ID
   */
  firstTransactionId?: string;
  /**
   * 請求金額
   */
  amount: number;
  /**
   * 購入者
   */
  customer: AtoneCustomer;
  /**
   * サービス提供先
   */
  destCustomers?: AtoneDestCustomers[];
  /**
   * 商品情報（Atone）
   */
  items: AtoneItem[];
  /**
   * サービス提供者
   */
  serviceSupplier?: AtoneServiceSupplier;
  /**
   * 販売形態
   */
  salesForm?: AtoneCapturePostRequestSalesFormEnum;
  /**
   * 流入元媒体
   */
  sourceMedia?: string;
};

/**
 * atoneLite決済パラメタ
 * atoneと基本同じ。型情報のみ異なる。
 */
export type AtoneLitePayment = TypeReplace<
  AtonePayment,
  {
    transactionOptions: LiteCapturePostRequestTransactionOptionsEnum[];
    salesForm: LiteCapturePostRequestSalesFormEnum;
  }
>;

export type OldAtonePayment = {
  /**
   * 加盟店取引ID
   */
  shopTransactionNo: string;
  /**
   * チェックサム
   */
  checksum: string;
  /**
   * 取引オプション
   */
  transactionOptions: AtoneCapturePostRequestTransactionOptionsEnum[];
  /**
   * ユーザID
   */
  userNo?: string;
  /**
   * 売り上げ確定
   */
  salesSettled?: boolean;
  /**
   * 加盟店取引備考
   */
  descriptionTrans?: string;
  /**
   * 定期初回のNP取引ID
   */
  firstTransactionId?: string;
  /**
   * 請求金額
   */
  amount: number;
  /**
   * 購入者
   */
  customer: AtoneCustomer;
  /**
   * サービス提供先
   */
  destCustomers?: AtoneDestCustomers[];
  /**
   * 商品情報（Atone）
   */
  items: AtoneItem[];
  /**
   * サービス提供者
   */
  serviceSupplier: OldAtoneServiceSupplier;
  /**
   * 販売形態
   */
  salesForm?: AtoneCapturePostRequestSalesFormEnum;
  /**
   * 流入元媒体
   */
  sourceMedia?: string;
};

/**
 * NP後払い決済パラメタ
 */
export type NpAtobaraiPayment = {
  /**
   * 加盟店取引ID
   */
  shopTransactionNo: string;
  /**
   * チェックサム
   */
  checksum: string;
  /**
   * 請求種別
   */
  billOption: {
    billType: NpAtobaraiRegistTransactionPostRequestPaymentBillTypeOptionBillTypeEnum;
  };
  /**
   * サイト名
   */
  siteName?: string;
  /**
   * サイトURL
   */
  siteUrl?: string;
  /**
   * 取引オプション
   */
  transactionOptions: NpAtobaraiRegistTransactionPostRequestTransactionOptionsEnum[];
  /**
   * 定期初回のNP取引ID
   */
  firstTransactionId?: string;
  /**
   * 審査オプション
   */
  authoriOption?: AuthoriOptions;
  /**
   * 請求金額
   */
  amount: number;
  /**
   * 購入者
   */
  customer: NpAtobaraiCustomer;
  /**
   * サービス提供先
   */
  destCustomers?: NpAtobaraiDestCustomers[];
  /**
   * 商品情報（NP後払い）
   */
  items: NpAtobaraiItem[];
  /**
   * 販売形態
   */
  salesForm?: NpAtobaraiRegistTransactionPostRequestSalesFormEnum;
  /**
   * 流入元媒体
   */
  sourceMedia?: string;
};

/**
 * Atone認証用パラメータ
 * @see https://st-integration-manual.np-atobarai.info/authentication_parameters
 */
export type AtoneModalParameter = {
  /**
   * サービス種別（Atone）
   */
  serviceType: typeof SERVICE_TYPE.ATONE;
  /**
   * モーダルモード（決済）
   */
  modalMode?: typeof MODAL_MODE.SETTLEMENT;
  /**
   * 店舗公開可能鍵
   */
  pubKey: string;
  /**
   * 端末ID
   */
  terminalId?: string;
  /**
   * 決済データ
   */
  payment: AtonePayment;
  /**
   * 事前指定トークン
   */
  preToken?: string;
};

/**
 * Atone認証用パラメータ
 * @see https://st-integration-manual.np-atobarai.info/authentication_parameters
 */
export type AtoneLiteModalParameter = {
  /**
   * サービス種別（Atone）
   */
  serviceType: typeof SERVICE_TYPE.ATONE_LITE;
  /**
   * モーダルモード（決済）
   */
  modalMode?: typeof MODAL_MODE.SETTLEMENT;
  /**
   * 店舗公開可能鍵
   */
  pubKey: string;
  /**
   * 端末ID
   */
  terminalId?: string;
  /**
   * 決済データ
   */
  payment: AtoneLitePayment;
  /**
   * 事前指定トークン
   */
  preToken?: string;
};

export type OldAtoneModalParameter = {
  /**
   * サービス種別なし
   */
  serviceType: undefined;

  /**
   * モード指定なし（決済モーダル）
   */
  modalMode: undefined;

  /**
   * 店舗公開可能鍵
   */
  pubKey: string;

  /**
   * 端末ID
   */
  terminalId?: string;

  /**
   * 決済データ
   */
  payment: Omit<AtonePayment, 'description' | 'serviceSupplier'> & {
    descriptionTrans?: string;
    serviceSupplier: OldAtoneServiceSupplier;
  };

  /**
   * 事前指定トークン
   */
  preToken?: string;
};

/**
 * NP後払い認証用パラメータ
 * @see https://st-integration-manual.np-atobarai.info/authentication_parameters
 */
export type NpAtobaraiModalParameter = {
  /**
   * サービス種別（NP後払い）
   */
  serviceType: typeof SERVICE_TYPE.ATOBARAI;

  /**
   * モーダルモード（決済モーダル）
   */
  modalMode?: typeof MODAL_MODE.SETTLEMENT;

  /**
   * 店舗公開可能鍵
   */
  pubKey: string;

  /**
   * 端末ID
   */
  terminalId: string;

  /**
   * 決済データ
   */
  payment: NpAtobaraiPayment;
};

export type AtoneAuthModalParameter = {
  /**
   * サービス種別（認証サービス）
   */
  serviceType: typeof SERVICE_TYPE.ATONE;

  /**
   * モーダルモード（認証モーダル）
   */
  modalMode: typeof MODAL_MODE.AUTH;

  /**
   * 店舗公開可能鍵
   */
  pubKey: string;

  /**
   * 端末ID
   */
  terminalId?: string;
  /**
   * 事前指定トークン
   */
  preToken?: string;

  /**
   * atoneで使用されるpaymentデータから、customerとuser_noを使用する。
   * そのほかのものは見ない。
   */
  payment: {
    /**
     * 顧客情報
     */
    customer?: AtoneCustomer;

    /**
     * ユーザーID
     */
    userNo?: string;
  } & Record<string, unknown>;
};

export type AtoneLiteAuthModalParameter = {
  /**
   * サービス種別（認証サービス）
   */
  serviceType: typeof SERVICE_TYPE.ATONE_LITE;

  /**
   * モーダルモード（認証モーダル）
   */
  modalMode: typeof MODAL_MODE.AUTH;

  /**
   * 店舗公開可能鍵
   */
  pubKey: string;

  /**
   * 端末ID
   */
  terminalId?: string;

  /**
   * 事前指定トークン
   */
  preToken?: string;

  /**
   * atoneで使用されるpaymentデータから、customerとuser_noを使用する。
   * そのほかのものは見ない。
   */
  payment: {
    /**
     * 顧客情報
     */
    customer?: AtoneCustomer;

    /**
     * ユーザーID
     */
    userNo?: string;
  } & Record<string, unknown>;
};

/**
 * 認証用パラメータ
 *
 * プロパティがcamelCase
 */
export type ModalParameter =
  | AtoneModalParameter
  | AtoneLiteModalParameter
  | NpAtobaraiModalParameter
  | OldAtoneModalParameter
  | AtoneAuthModalParameter
  | AtoneLiteAuthModalParameter;

/**
 * 生の認証用パラメータ
 *
 * プロパティがsnake_case
 */
export type RawModalParameter = SnakeCasedPropertiesDeep<ModalParameter>;

/**
 * このエラータイプはAPIの結果をフロントやjs error apiに送信する時の分類。
 * js例外の場合は、js例外のメッセージやエラークラスがそのまま使われる。
 */
export const ERROR_TYPE = {
  INTERNAL_EXCEPTION: {
    name: 'InternalException',
    message: '内部サーバーエラー、もしくは技術的な問題が発生しています。',
  },
  REQUEST_EXCEPTION: {
    name: 'RequestException',
    message: '加盟店様へ問い合わせしてください。',
  },
};

export type ErrorType = {
  serviceType?: ServiceType;
  name: string;
  message: string;
  errors?: AtoneErrorMessage[];
};

export type PrePaymentCheckResult = {
  error?: {
    message: string;
    stack: string;
  };
  result: boolean;
};

export type AtoneSuccessFailureCallbackParameter = {
  // マニュアル記載パラメータ
  id: string;
  shopTransactionNo: string;
  authorizationResult: number;
  authorizationResultNgReason: number;

  // 旧システムで送信されてるその他のパラメタ。渡しちゃダメなものなはずだが、依存してるショップがあるかもしれないので返しておく。
  subtractPoint: number;
  authorizationResultNgMessage: string[];
  appDownloadFlg: boolean;
  additionalAuthorizationResultNgReason: number;
};
export type AtoneSucceededCallbackParameter = {
  companyCode: string;
  shopBranchCode: string;
  transactionId: string;
  shopTransactionNo: string;
  authorizationResult: string;
};
export type AtobaraiSucceededCallbackParameter = {
  shopCode: string;
  transactionId: string;
  shopTransactionNo: string;
  authorizationResult: string;
};
export type AtoneLiteSucceededCallbackParameter = {
  companyCode: string;
  shopBranchCode: string;
  transactionId: string;
  shopTransactionNo: string;
  authorizationResult: string;
};
export type AtoneFailedCallbackParameter = {
  transactionId: string;
  companyCode: string;
  shopBranchCode: string;
  shopTransactionNo: string;
  authorizationResult: string;
  authorizationResultNgReason: string;
};
export type AtoneLiteFailedCallbackParameter = {
  transactionId: string;
  companyCode: string;
  shopBranchCode: string;
  shopTransactionNo: string;
  authorizationResult: string;
  authorizationResultNgReason: string;
};
export type AtoneAuthFailedCallbackParameter = {
  transactionId: string;
  companyCode: string;
  shopBranchCode: string;
  shopTransactionNo: string;
  authorizationResult: string;
  authorizationResultNgReason: string;
};
export type AtobaraiFailedCallbackParameter = {
  transactionId: string;
  shopCode: string;
  shopTransactionNo: string;
  authorizationResult: string;
  authorizationResultNgReason: string;
};

export type AuthenticatedCallbackParameter = {
  authenticationToken: string;
  userNo?: string;
};

/**
 * ショップサイトからモーダルに連携するメッセージ
 *
 * payloadは全てsnake_case
 */
export type MessageToModal =
  | {
      type: 'INIT';
      payload: RawModalParameter[];
    }
  | {
      type: 'START';
      payload: RawModalParameter;
    };

/**
 * TypeGuardです。モーダル起動時に渡されたパラメータがAtoneのパラメータかどうか検証します。
 * @param value
 * @returns
 */
export const isAtoneModalParameter = (value: ModalParameter): value is AtoneModalParameter =>
  includes(undefined, SERVICE_TYPE.ATONE)(value.serviceType);

export const isAtoneLiteModalParameter = (value: ModalParameter): value is AtoneModalParameter =>
  includes(undefined, SERVICE_TYPE.ATONE_LITE)(value.serviceType);

/**
 * TypeGuardです。モーダル起動時に渡されたパラメータがNP後払いのパラメータかどうか検証します。
 * @param value
 * @returns
 */
export const isNpAtobaraiModalParameter = (value: ModalParameter): value is NpAtobaraiModalParameter =>
  includes(SERVICE_TYPE.ATOBARAI)(value.serviceType);

export const createModalContextValue = () => {
  const origin = ref<string>('');
  const setOrigin = (value: string) => {
    origin.value = value;
  };

  const isSafeOrigin = ref<boolean>(true);
  const onOriginError = () => {
    isSafeOrigin.value = false;
  };

  return shallowReactive({
    origin,
    setOrigin,
    isSafeOrigin,
    onOriginError,
  });
};

export const ModalParameterContext = createContext(createModalContextValue);
