import { z } from 'zod';
import { npCancelCallbackSchema, npErrorCallbackSchema } from '@schemas/common-schemas/common-callback-schema';

/**
 * NP後払いのsucceededコールバックスキーマ
 *
 * atoneとは引数型が異なる。
 */
const npModuleNpAtobaraiSettlementSucceededCallbackSchema = <T extends z.ZodTypeAny, U extends z.ZodTypeAny>(service: {
  service_type: T;
  modal_mode: U;
}) =>
  z
    .function(
      // 引数型
      z.tuple([
        z.object({
          service_type: service.service_type,
          modal_mode: service.modal_mode,
          shop_code: z.string(),
          transaction_id: z.string(),
          shop_transaction_no: z.string(),
          authorization_result: z.string(),
        }),
      ]),
      // 戻り値型は指定のないものに関してはunknownとする（システム的には使用しないため、ショップは任意の戻り値を返しても腐らない）。
      z.unknown(),
    )
    .default(() => () => {});

/**
 * NP後払いのfailedコールバックスキーマ
 *
 * atoneとは引数が異なる。
 */
const npModuleNpAtobaraiFailedCallbackSchema = <T extends z.ZodTypeAny, U extends z.ZodTypeAny>(service: {
  service_type: T;
  modal_mode: U;
}) =>
  z
    .function(
      z.tuple([
        z
          .object({
            service_type: service.service_type,
            modal_mode: service.modal_mode,
            shop_code: z.string(),
            transaction_id: z.string(),
            shop_transaction_no: z.string(),
            authorization_result: z.string(),
            authorization_result_ng_reason: z.string(),
          })
          .partial()
          .required({ service_type: true, modal_mode: true }),
      ]),
      z.unknown(),
    )
    .default(() => () => {});

/**
 * 会員サイト側で実装するコールバックの中で、実行されているサービスを特定するための情報。
 */
const npAtobaraiSettlementServiceCallbackClassifier = z.object({
  service_type: z.literal('02'),
  modal_mode: z.literal('01'),
});

/**
 * NP後払い決済関連のコールバックスキーマ。
 */
export const npAtobaraiSettlementCallbackDefinition = {
  /**
   * コールバック実行時にModal側から送られるメッセージに含まれる。
   *
   * 通常のコールバックはサービスに紐づいてる。どのサービスのコールバックを呼ぶか、これを持ってして決める。
   */
  service: { service_type: '02', modal_mode: '01' } as const,
  /**
   * コンフィグパラメータに含まれるコールバックをパースする時に使用する。
   *
   * // TODO: 多分消せる。serviceプロパティでなんとかできるんじゃないかと思う。
   */
  classifier: npAtobaraiSettlementServiceCallbackClassifier,
  /**
   * スキーマ本体。
   */
  schema: z.object({
    succeeded: npModuleNpAtobaraiSettlementSucceededCallbackSchema(npAtobaraiSettlementServiceCallbackClassifier.shape),
    failed: npModuleNpAtobaraiFailedCallbackSchema(npAtobaraiSettlementServiceCallbackClassifier.shape),
    cancelled: npCancelCallbackSchema(npAtobaraiSettlementServiceCallbackClassifier.shape),
    error: npErrorCallbackSchema(npAtobaraiSettlementServiceCallbackClassifier.shape),
  }),
};
