/**
 * ReactのContextをVueに移植した。使い方、使い勝手等全く同じなので使用法についてはReactの公式を参照するように。
 */
import { defineComponent, PropType, provide, inject, h, useSlots, Ref, ref, toRef } from '@vue/composition-api';
import { randomString } from '@berlin-front/common/utils';

type Callable<P, R> = (arg: P) => R;

type GenericReturnType<P, X> = X extends Callable<P, unknown> ? ReturnType<X> : never;

export type Context<T> = {
  symbol: symbol;
  provider: GenericReturnType<{ props: { value: { type: PropType<T> } } }, typeof defineComponent>;
  consumer: ReturnType<typeof defineComponent>;
};

const defaultValues: { key: symbol; value: unknown }[] = [];

const None = Symbol('None');

export const createContext = <T>(defaultValue: T | (() => T), name = ''): Context<T> => {
  const dynamicName = name || randomString();
  const symbol = Symbol(dynamicName);
  defaultValues.push({ key: symbol, value: defaultValue });
  const providerName = `DynamicProvider-${dynamicName}`;
  const consumerName = `DynamicConsumer-${dynamicName}`;
  return {
    symbol,
    provider: defineComponent({
      name: providerName,
      props: {
        value: {
          type: Object as PropType<T>,
          required: false,
        },
      },
      setup(props, context) {
        let refValue: Ref<T>;
        if (props.value) {
          refValue = toRef(props, 'value') as Ref<T>;
        } else if (typeof defaultValue === 'function') {
          refValue = ref((defaultValue as () => T)()) as Ref<T>;
        } else {
          refValue = ref(defaultValue) as Ref<T>;
        }
        provide(symbol, refValue);
        return () => {
          if (context.slots.default) {
            const nodes = context.slots.default();
            if (nodes.length <= 1) {
              return nodes[0];
            }
            return h('div', {}, nodes);
          }
          return undefined;
        };
      },
    }),
    consumer: defineComponent({
      name: consumerName,
      setup(_props) {
        const rawVal = inject(symbol, None) as Ref<T> | typeof None;
        let ctxObject: Ref<T>;
        if (rawVal === None) {
          if (typeof defaultValue === 'function') {
            ctxObject = ref((defaultValue as () => T)()) as Ref<T>;
          }
          ctxObject = ref(defaultValue) as Ref<T>;
        } else {
          ctxObject = rawVal;
        }
        const slots = useSlots();
        return () => {
          if (slots.default) {
            const nodes = slots.default(ctxObject);
            if (!nodes) {
              return undefined;
            }
            if (nodes.length <= 1) {
              return nodes[0];
            }
            h('div', {}, nodes);
          }
          return undefined;
        };
      },
    }),
  };
};

export type ContextValueType<T extends Context<unknown>> = T extends Context<infer U> ? U : never;

export const useContext = <T extends Context<unknown>>(context: T): ContextValueType<T> => {
  const defaultValue = defaultValues.find(({ key }) => key === context.symbol)?.value;
  const { symbol } = context;
  const rawVal = inject(symbol, None) as unknown as Ref<ContextValueType<T>> | typeof None;
  let ctxObject: Ref<ContextValueType<T>>;
  if (rawVal === None) {
    if (typeof defaultValue === 'function') {
      ctxObject = ref((defaultValue as () => ContextValueType<T>)()) as Ref<ContextValueType<T>>;
    }
    ctxObject = ref(defaultValue) as Ref<ContextValueType<T>>;
  } else {
    ctxObject = rawVal;
  }
  return ctxObject.value;
};
