import { z } from 'zod';
import {
  AtoneAuthenticatePostResponseSexDivisionEnum,
  AtoneCapturePostRequestTransactionOptionsEnum,
  AtoneCapturePostInputBodyCustomerIdentificationStatusEnum,
  AtoneCapturePostRequestSupplierIdentificationStatusEnum,
  AtoneCapturePostInputBodyCustomerPastPaymentWayEnum,
  AtoneCapturePostRequestSalesFormEnum,
  AtoneMemberRegisterPostRequestSexDivisionEnum,
} from '@netprotections/berlin-bff';
import {
  allowEmpty,
  allowEmptyInputWithDefault,
  bool,
  mandatoryStr,
  excludeEmptyArray,
  num,
  numericalEnum,
  numericalStringEnum,
  str,
} from './schema-utils';

/**
 * merchandiseCategory
 *
 * 本来 `string[]` 型だけど、 `string[][]` のような2次元型が与えられた場合、2次元配列の最初の配列を返却する。
 */
export const merchandiseCategory = z.preprocess((arg, _ctx) => {
  if (
    Array.isArray(arg) && // 入力値が配列で、
    arg.length > 0 && // 一つ以上のメンバーを持ち、
    Array.isArray(arg[0]) && // 一つ目のメンバーが配列で（つまり2次元配列、
    (arg[0] as unknown[]).every((v) => str.safeParse(v).success) // 2次元配列の最初の配列の中身が文字列型なら
  ) {
    // 2次元配列の最初の配列を返却する。
    return arg[0];
  }
  return arg;
}, excludeEmptyArray(str)) as unknown as z.ZodEffects<
  z.ZodArray<z.ZodEffects<z.ZodString, string, string>, 'many'>,
  string[],
  string[]
>;

/**
 * pastMerchandiseCategory
 *
 * 過去商材カテゴリ配列の配列なので `string[][]` 型が正しいが、 `string[]` 型が入った時、正しい型に変換する。
 */
const pastMerchandiseCategory = z.preprocess(
  (arg, _ctx) => {
    if (z.array(z.union([str, z.null(), z.undefined()])).safeParse(arg).success) {
      return [arg];
    }
    return arg;
  },
  excludeEmptyArray(excludeEmptyArray(str)),
) as unknown as z.ZodEffects<
  z.ZodArray<z.ZodArray<z.ZodEffects<z.ZodString, string, string>, 'many'>, 'many'>,
  [string, ...string[]][],
  [string, ...string[]][]
>;

/**
 * atoneのpayment.customerのスキーマ。
 *
 * 全てoptionalプロパティ。
 */
export const atoneCustomerSchema = z
  .object({
    customer_name: allowEmpty(str),
    customer_family_name: allowEmpty(str),
    customer_given_name: allowEmpty(str),
    customer_name_kana: allowEmpty(str),
    customer_family_name_kana: allowEmpty(str),
    customer_given_name_kana: allowEmpty(str),
    company_name: allowEmpty(str),
    department: allowEmpty(str),
    zip_code: allowEmpty(str),
    address: allowEmpty(str),
    tel: allowEmpty(str),
    phone_number: allowEmpty(str),
    email: allowEmpty(str),
    total_purchase_amount: allowEmpty(num),
    total_purchase_count: allowEmpty(num),
    sex_division: allowEmpty(numericalStringEnum(AtoneAuthenticatePostResponseSexDivisionEnum)),
    birthday: allowEmpty(str),
    identification_status: allowEmpty(
      excludeEmptyArray(numericalEnum(AtoneCapturePostInputBodyCustomerIdentificationStatusEnum)),
    ),
    shop_customer_id: allowEmpty(str),
    membership_period: allowEmpty(num),
    terminal_id: allowEmpty(str),
    past_merchandise_category: allowEmpty(pastMerchandiseCategory),
    past_brand_name: allowEmpty(excludeEmptyArray(str)),
    past_payment_way: allowEmpty(excludeEmptyArray(numericalEnum(AtoneCapturePostInputBodyCustomerPastPaymentWayEnum))),
    shop_customer_seller_code: allowEmpty(str),
  })
  .passthrough();

/**
 * 認証モーダルのpayment.customerのスキーマ。
 *
 * - checksumとか無いのでパススルーしない。
 * - プリセットパラメータ以外の項目は捨てる。
 */
export const atoneAuthCustomerSchema = z.object({
  customer_family_name: allowEmpty(str),
  customer_given_name: allowEmpty(str),
  customer_family_name_kana: allowEmpty(str),
  customer_given_name_kana: allowEmpty(str),
  zip_code: allowEmpty(str),
  address: allowEmpty(str),
  phone_number: allowEmpty(str),
  email: allowEmpty(str),
  sex_division: z.preprocess(
    (arg) => {
      if (
        // prettier-ignore
        (
          // 整数っぽい文字列
          (typeof arg === 'string' && /^\d+$/.test(arg)) ||
          // 非負整数値
          (typeof arg === 'number' && Number.isInteger(arg) && arg >= 0)
        ) &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Object.values(AtoneMemberRegisterPostRequestSexDivisionEnum).indexOf(String(arg) as unknown as any)
      ) {
        // 対応するEnum値と一致しない場合、 `'2'` 女性に寄せる。
        // eslint-disable-next-line no-underscore-dangle
        return AtoneMemberRegisterPostRequestSexDivisionEnum._2;
      }
      // それとは関係ない場合はスルー。
      return arg;
    },
    allowEmpty(numericalStringEnum(AtoneAuthenticatePostResponseSexDivisionEnum)),
  ) as unknown as z.ZodEffects<
    z.ZodOptional<z.ZodEffects<z.ZodAny, AtoneAuthenticatePostResponseSexDivisionEnum, '1' | '2'>>,
    AtoneAuthenticatePostResponseSexDivisionEnum | undefined,
    '1' | '2' | undefined
  >,
  birthday: allowEmpty(str),
});

export const atoneDestCustomerSchema = z
  .object({
    dest_customer_name: allowEmpty(str),
    dest_customer_name_kana: allowEmpty(str),
    dest_company_name: allowEmpty(str),
    dest_department: allowEmpty(str),
    dest_zip_code: allowEmpty(str),
    dest_address: allowEmpty(str),
    dest_tel: allowEmpty(str),
  })
  .passthrough();

export const atoneItemSchema = z
  .object({
    shop_item_id: mandatoryStr,
    item_name: mandatoryStr,
    item_price: num,
    item_count: num,
    item_url: allowEmpty(str),
    merchandise_category: allowEmpty(merchandiseCategory),
    brand_name: allowEmpty(str),
    shop_seller_code: allowEmpty(str),
    original_price: allowEmpty(num),
    list_price: allowEmpty(num),
  })
  .passthrough();

export const atoneServiceSupplierSchema = z
  .object({
    shop_customer_id: allowEmpty(str),
    supplier_user_id: allowEmpty(str),
    // こいつだけ値非必須。allowEmpty使う場合はpartialで一括指定すると型が深くなっちゃうから,
    // 他のプロパティに一つずつoptionalつけてる。
    membership_start_date: allowEmpty(str),
    membership_period: allowEmpty(num), //
    // AtoneCapturePostRequestSupplierIdentificationStatusEnum
    identification_status: allowEmpty(
      excludeEmptyArray(numericalEnum(AtoneCapturePostRequestSupplierIdentificationStatusEnum)),
    ),
    total_sales_count: allowEmpty(num),
    total_sales_amount: allowEmpty(num),
    past_merchandise_category: allowEmpty(pastMerchandiseCategory),
  })
  .passthrough()
  // supplier_user_idは旧Atoneにおいてはshop_customer_idだった。
  // そのため、必要に応じてshop_customer_idをsupplier_user_idに変換する。
  // object型でproperty-nameの付け替えを行う時は基本的にpreprocessではなくtransformで変換するが、
  // transformの戻り値側にはshop_customer_idは型的に含まれないようになってる。input型とoutput型が異なる
  .transform(({ shop_customer_id, ...rest }) => {
    if (typeof shop_customer_id === 'string' && !('supplier_user_id' in rest)) {
      return { supplier_user_id: shop_customer_id, ...rest };
    }
    return rest;
  });

export const atoneRawPaymentSchema = z
  .object({
    /* required */
    checksum: mandatoryStr,
    amount: num,
    shop_transaction_no: mandatoryStr,
    items: excludeEmptyArray(atoneItemSchema),
    /* optional */
    sales_settled: allowEmpty(bool),
    transaction_options: allowEmpty(
      excludeEmptyArray(numericalStringEnum(AtoneCapturePostRequestTransactionOptionsEnum)),
    ),
    user_no: allowEmpty(str),
    description: allowEmpty(str),
    description_trans: allowEmpty(str), // 旧atoneはdescription->description_transになる。
    source_media: allowEmpty(str),
    sales_form: allowEmpty(numericalEnum(AtoneCapturePostRequestSalesFormEnum)),
    customer: allowEmptyInputWithDefault(atoneCustomerSchema, () => ({})),
    dest_customers: allowEmpty(excludeEmptyArray(atoneDestCustomerSchema)),
    service_supplier: allowEmpty(atoneServiceSupplierSchema),
  })
  .passthrough();

export const atonePaymentSchema = atoneRawPaymentSchema
  // 旧atoneのpayment.description_transは、統合IFでpayment.descriptionに変更された。
  // 旧パラメータに基づくプロパティは統合IFのプロパティに変換する。
  .transform(({ description_trans, ...v }) => {
    if (typeof description_trans === 'string' && !('description' in v)) {
      return { description: description_trans, ...v };
    }
    return v;
  });

export const atoneAuthPayment = allowEmptyInputWithDefault(
  z.object({
    user_no: allowEmpty(str),
    customer: allowEmptyInputWithDefault(atoneAuthCustomerSchema, () => ({})),
  }),
  () => ({}),
);

/** 認証コールバック */
export const atoneAuthenticatedCallbackSchema = <T extends z.ZodTypeAny, U extends z.ZodTypeAny>(classifier: {
  service_type: T;
  modal_mode: U;
}) =>
  z
    .function(
      z.tuple([
        z.object({
          service_type: classifier.service_type,
          modal_mode: classifier.modal_mode,
          authentication_token: z.string(),
          user_no: z.string().optional(),
        }),
      ]),
      z.unknown(),
    )
    .default(() => () => {});

/** 成功コールバック */
export const atoneSucceededCallbackSchema = <T extends z.ZodTypeAny, U extends z.ZodTypeAny>(classifier: {
  service_type: T;
  modal_mode: U;
}) =>
  z
    .function(
      z.tuple([
        z.object({
          service_type: classifier.service_type,
          modal_mode: classifier.modal_mode,
          company_code: z.string(),
          shop_branch_code: z.string(),
          transaction_id: z.string(),
          shop_transaction_no: z.string(),
          authorization_result: z.string(),
        }),
      ]),
      z.unknown(),
    )
    .default(() => () => {});

/** 失敗コールバック */
export const atoneFailedCallbackSchema = <T extends z.ZodTypeAny, U extends z.ZodTypeAny>(classifier: {
  service_type: T;
  modal_mode: U;
}) =>
  z
    .function(
      z.tuple([
        z
          .object({
            service_type: classifier.service_type,
            modal_mode: classifier.modal_mode,
            company_code: z.string(),
            shop_branch_code: z.string(),
            transaction_id: z.string(),
            shop_transaction_no: z.string(),
            authorization_result: z.string(),
            authorization_result_ng_reason: z.string(),
          })
          .partial()
          .required({
            service_type: true,
            modal_mode: true,
          }),
      ]),
      z.unknown(),
    )
    .default(() => () => {});

/** 決済前コールバック */
export const atonePrePaymentCallbackSchema = <T extends z.ZodTypeAny, U extends z.ZodTypeAny>(classifier: {
  service_type: T;
  modal_mode: U;
}) =>
  z
    .function(
      z.tuple([
        z.object({
          service_type: classifier.service_type,
          modal_mode: classifier.modal_mode,
          payment: z.unknown(),
        }),
      ]),
      z.union([z.boolean(), z.promise(z.boolean())]),
    )
    .default(() => () => true);

export const atoneInvalidCallbackSchema = <T extends z.ZodTypeAny, U extends z.ZodTypeAny>(classifier: {
  service_type: T;
  modal_mode: U;
}) =>
  z
    .function(
      z.tuple([
        z.object({
          service_type: classifier.service_type,
          modal_mode: classifier.modal_mode,
        }),
      ]),
      z.unknown(),
    )
    .default(() => () => {});
