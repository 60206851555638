/* eslint-disable no-var */
import { DeepOmit } from 'deep-utility-types';
import type { AtoneAuthServiceInputType } from '@schemas/atone-auth/AtoneAuthParameterSchema';
import { legacyAtoneAuthCallbackDefinition } from '@schemas/atone-auth/LegacyAtoneAuthCallbackSchema';
import type commonParamAdditionalSpace from '@schemas/common-schemas/common-param-additional-space';
import ModalDriver from './ModalDriver';

type AdditionalSpaceParam = typeof commonParamAdditionalSpace._input;

const SERVICE = {
  service_type: '01',
  modal_mode: '02',
};

type AtoneRegisterConfig = DeepOmit<
  AtoneAuthServiceInputType,
  // 以下は固定なので入力できない。
  'is_legacy' | 'service_type' | 'modal_mode'
> &
  typeof legacyAtoneAuthCallbackDefinition.schema._input &
  AdditionalSpaceParam;

/**
 * AtoneRegister.configに渡された最新のパラメータを保持する。
 */
let rawConfig: AtoneRegisterConfig | undefined;

const AtoneRegister = {
  /**
   * 認証パラメータを指定して、認証モーダルの起動準備を行います。
   *
   * @param config 認証パラメータをセットします。
   * @param callback Deprecated: config終了後に実行したい関数がある場合セットします。Atone.configのcallback
   */
  config: (config: AtoneRegisterConfig, callback?: () => void): void => {
    rawConfig = config;
    // configをコールバックと値に分ける。
    /** パラメータ中のコールバック */
    const callbacks = Object.fromEntries(
      Object.entries(config).flatMap(([k, v]) => (typeof v === 'function' ? [[k, v]] : [])),
    );
    /** パラメータ中のコールバック以外のもの。 */
    const parameters = Object.fromEntries(
      Object.entries(config).flatMap(([k, v]) => (typeof v !== 'function' ? [[k, v]] : [])),
    );
    const wrappedCallback = legacyAtoneAuthCallbackDefinition.schema.parse(callbacks);

    ModalDriver.init([
      {
        ...parameters,
        ...wrappedCallback,
        // 固定パラメータ。固定パラメータを末尾に配置するのは上書きされないように。
        ...SERVICE,
        is_legacy: true,
      },
    ]);
    if (callback) {
      console.warn('第二引数は非推奨です。');
      callback();
    }
  },
  /**
   * AtoneRegister翌月後払いの決済を開始します。
   */
  start: (): void => {
    ModalDriver.start(SERVICE);
  },
  /**
   * すでに設定済みのConfigに、引数を浅いマージします。
   * @deprecated 過去バージョンとの互換性のために残している。AtoneRegister.configを重複して呼ぶことができ、そちらの方が自由度が高いため、基本的にそちらを使用すること。
   */
  merge: (partialConfig: Partial<AtoneRegisterConfig>): void => {
    if (rawConfig) {
      AtoneRegister.config({ ...rawConfig, ...partialConfig });
    }
  },
  /**
   * @deprecated マージ結果などをモーダルに送信するタイミングはModalDriverが適切に判定するため、syncを呼ぶ必要はないし、現在、このメソッドは何もしない。
   */
  sync: (): void => {},
  /**
   * モーダルのロードが完了したかチェックするためのメソッドです。
   *
   * クライアントはこれを確認してからモーダルに対しコマンドを送信する仕様でしたが、現在、モーダルに対する各種操作は、ドライバー側でロードが完了するまで保留されるため\
   * このメソッドを使用する意義はありません。このメソッドは常にtrueを返します。
   * @deprecated
   */
  loaded: (): true => true,
  /**
   * 最後にセットされたconfigパラメータを返します。
   *
   * これはmergeによる修正も含みます。
   * @deprecated
   */
  properties: (): AtoneRegisterConfig | undefined => rawConfig,
};

export default AtoneRegister;
