import { z } from 'zod';
import {
  atoneAuthenticatedCallbackSchema,
  atoneFailedCallbackSchema,
  atoneInvalidCallbackSchema,
  atonePrePaymentCallbackSchema,
  atoneSucceededCallbackSchema,
} from '@schemas/common-schemas/atone-common-schema';
import { npCancelCallbackSchema, npErrorCallbackSchema } from '@schemas/common-schemas/common-callback-schema';

/**
 * 会員サイト側で実装するコールバックの中で、実行されているサービスを特定するための情報。
 */
const atoneLiteSettlementServiceCallbackClassifier = z.object({
  service_type: z.literal('04'),
  modal_mode: z.literal('01'),
});

export const atoneLiteSettlementCallbackDefinition = {
  /**
   * コールバック実行時にModal側から送られるメッセージに含まれる。
   *
   * 通常のコールバックはサービスに紐づいてる。どのサービスのコールバックを呼ぶか、これを持ってして決める。
   */
  service: { service_type: '04', modal_mode: '01' } as const,
  /**
   * コンフィグパラメータに含まれるコールバックをパースする時に使用する。
   *
   * // TODO: 多分消せる。serviceプロパティでなんとかできるんじゃないかと思う。
   */
  classifier: atoneLiteSettlementServiceCallbackClassifier,
  /**
   * スキーマ本体。
   */
  schema: z.object({
    authenticated: atoneAuthenticatedCallbackSchema(atoneLiteSettlementServiceCallbackClassifier.shape),
    succeeded: atoneSucceededCallbackSchema(atoneLiteSettlementServiceCallbackClassifier.shape),
    failed: atoneFailedCallbackSchema(atoneLiteSettlementServiceCallbackClassifier.shape),
    cancelled: npCancelCallbackSchema(atoneLiteSettlementServiceCallbackClassifier.shape),
    pre_payment: atonePrePaymentCallbackSchema(atoneLiteSettlementServiceCallbackClassifier.shape),
    invalid: atoneInvalidCallbackSchema(atoneLiteSettlementServiceCallbackClassifier.shape),
    error: npErrorCallbackSchema(atoneLiteSettlementServiceCallbackClassifier.shape),
  }),
};
