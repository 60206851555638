import { preprocess, z } from 'zod';

/**
 * 文字列スキーマ
 *
 * 期待値はstringだけど、numberとbooleanも入力できる。変換可能な入力は文字列に変換される。\
 * `z.coerce.string()`はrequiredにできないみたいなので使わない。
 */
export const str = z.preprocess((arg, _ctx) => {
  if ((typeof arg === 'number' && !Number.isNaN(arg)) || typeof arg === 'boolean') return `${arg}`;
  return arg;
}, z.string()) as z.ZodEffects<z.ZodString>;

/**
 * 必須文字列スキーマ 空文字を許可しない
 *
 * 期待値はstringだけど、numberとbooleanも入力できる。変換可能な入力は文字列に変換される。\
 * `z.coerce.string()`はrequiredにできないみたいなので使わない。
 */
export const mandatoryStr = z.preprocess((arg, _ctx) => {
  if ((typeof arg === 'number' && !Number.isNaN(arg)) || typeof arg === 'boolean') return `${arg}`;
  return arg;
}, z.string().min(1)) as z.ZodEffects<z.ZodString>;

/**
 * 数数値スキーマ
 *
 * 期待値はnumberだけど、stringとbooleanも入力できる。変換可能な入力は数値に変換される。\
 * `z.coerce.number()`はrequiredにできないみたいなので使わない。
 */
export const num = z.preprocess((arg, _ctx) => {
  // booleanは false: 0, true: 1に変換される。
  if (typeof arg === 'boolean') return Number(arg);
  // 文字列については数値リテラル形式を満たすものを抽出し、数値変換する。
  if (typeof arg === 'string') {
    // Numberによる 文字 -> 数 型キャストは、10進以外ではマイナスを頭につけられないので、マイナスがあれば分離する。
    // 分離しないと、-0xa0 とか負数のn真数のキャストに失敗する。
    const isNegative = arg.startsWith('-');
    const absArg = arg.replace(/^-/, '');
    const normalizedNumericString = absArg
      // 1_000_000みたいに、数字の間に一個だけアンスコ入れられる。小数点やe10などアンスコ入り数を正規表現で判別するのが難しいため事前に除去する。
      .replace(/(\d)_(?=\d)/g, '$1')
      // jsでは `0` のみ頭につけた数は8進リテラルで、 `0o` つけたのと同じ扱いになる。しかし、`Number` 関数で `Number('015')` みたいに`o`がついてないと10進数としてパースされてしまうため `0o` に差し替える。
      .replace(/^0(?=\d)/, '0o');
    if (
      // 10進数として判定できるものを抽出。10進リテラル形式では 1.1e+1000 みたいに指数形式は受け付けない。
      /^\d*(\d+\.|\.\d+|\d+)\d*$/.test(normalizedNumericString) ||
      // 16進数として判定できるものを抽出。多分使われないと思う。
      /^0[xX][0-9a-fA-F]+$/.test(normalizedNumericString) ||
      // 8進数。多分使われないと思う。
      /^0[oO][0-7]+$/.test(normalizedNumericString) ||
      // 2進数。多分使われないと思う。
      /^0[bB][01]+$/.test(normalizedNumericString)
    ) {
      return (isNegative ? -1 : 1) * Number(normalizedNumericString);
    }
    // それ以外は変換しない
    return arg;
  }
  return arg;
}, z.number()) as z.ZodEffects<z.ZodNumber>;

/**
 * booleanスキーマ
 *
 * railsのルールに従いたいので `z.coerce.boolean()` は使わない。
 */
export const bool = z.preprocess((v) => {
  switch (typeof v) {
    case 'boolean': {
      return v;
    }
    case 'number': {
      // JSONのパーサなのでNaNは入力されないものとして考慮していない。
      return v !== 0;
    }
    case 'string': {
      // 空文字はNilとあるので、undefinedとして処理する。
      // https://github.com/rails/rails/blob/main/activemodel/lib/active_model/type/boolean.rb#L40-L41
      if (v === '') return undefined;
      // booleanの変換候補についてはrailsのルールから。
      // https://github.com/rails/rails/blob/main/activemodel/lib/active_model/type/boolean.rb#L15-L23
      return !['0', 'f', 'F', 'false', 'FALSE', 'off', 'OFF'].includes(v);
    }
    default: {
      // ここ、preprocessで変換できなかったやつはメインプロセスで適切にエラーになる。
      return v;
    }
  }
}, z.boolean()) as z.ZodEffects<z.ZodBoolean>;

/**
 * StringEnumオブジェクトの型をEnumLikeオブジェクトの型に変換する。
 *
 * @example
 *   // enumオブジェクトの型ををenum-likeオブジェクト
 *   EnumObjectToEnumLike<typeof XxxEnum>
 *
 * StringEnumって同値のstring値を代入できないって問題がある（数値Enumは大丈夫）から、StringEnumの型をEnumLikeに変換したくなることがある。
 * 何言ってるかっていうと
 *
 * @example
 *   enum Test {
 *     v1 = 'test'
 *   }
 *   let t1: Test = 'test'; // こいつはNGになって
 *   let t2: Test = Test.v1; // こいつはOKになる
 *
 *   // これの型は、{ v1: 'test' } になる。
 *   type EnumLikeTest = EnumObjectToEnumLikeObject<typeof Test>;
 *
 *   let t3: EnumLikeTest<keyof > = 'test'; // OK
 *   let t4: EnumLikeTest = Test.v1 // OK
 */
export type EnumObjectToEnumLikeObject<T> = { [K in keyof T]: T[K] extends string ? `${T[K]}` : T[K] };

/**
 * enum型をプリミティブ型のUnion型に変換する。
 */
export type EnumTypeToPrimitiveUnion<T> = T extends string ? `${T}` : T;

/**
 * StringEnumをEnumLikeに変換します。
 *
 * @param e
 * @returns
 */
export const enumToEnumLike = <T>(e: T): EnumObjectToEnumLikeObject<T> => e as unknown as EnumObjectToEnumLikeObject<T>;

/**
 * 数字enumスキーマ。
 *
 * 当システムではenum値としてint型の数値enumと整数の文字列形式('01'など)が使われているが、実装的には数も受け付ける。
 * （typescriptのスキーマ上は厳密にEnumに含まれる値のみを受け付けるが、型定義を使用せずにjsから使用した場合は数の入力を許容するということ。）
 *
 * parse実行時は常にenum値を返す。
 *
 * @param enumObj
 * @returns
 */
export const numericalStringEnum = <T extends z.EnumLike>(enumObj: T) =>
  z.preprocess((arg) => {
    if (typeof arg === 'number') {
      const value = Object.values(enumObj).find((v) => typeof v === 'string' && /^\d+/.test(v) && Number(v) === arg);
      return value !== undefined ? value : arg;
    }
    if (typeof arg === 'string') {
      // ズバリな文字列がある場合
      const value1 = Object.values(enumObj).find((v) => typeof v === 'string' && v === arg);
      if (typeof value1 === 'string') return value1;

      // 数値化したときに一致する文字列がある場合それを返す（最初に見つかったもの）。
      const value2 = Object.values(enumObj).find(
        (v) => typeof v === 'string' && [v, arg].every((target) => /^\d+$/.test(target)) && Number(v) === Number(arg),
      );
      if (typeof value2 === 'string') return value2;
    }
    return arg;
  }, z.nativeEnum(enumObj)) as unknown as z.ZodEffects<
    z.ZodAny, // こいつはどうでもいい。第二第三引数を省略した時にしか参照されないため。
    T[keyof T], // 出力型はenum値そのもの。
    T[keyof T] extends string ? `${T[keyof T]}` : T[keyof T] // 入力型は文字列enumの場合に関しては、値の等しい文字列でも型エラーにならないようにする。
  >;

/**
 * 数値enumスキーマ。
 *
 * jsonのフィールドをTSの数値enumに変換する。
 * 例えば文字列で '01' とか与えてしまった場合も適宜 0 に変換する。
 *
 * @param enumObj
 * @returns
 */
export const numericalEnum = <T extends z.EnumLike>(enumObj: T) =>
  z.preprocess((arg) => {
    if (typeof arg === 'number') return arg;
    if (typeof arg === 'string' && /^\d+$/.test(arg)) {
      const argNum = Number(arg);
      const result = Object.values(enumObj).find((v) => v === argNum);

      if (typeof result === 'number') return result;
    }
    return arg;
  }, z.nativeEnum(enumObj)) as unknown as z.ZodEffects<z.ZodNativeEnum<T>>;

/**
 * パース後、空要素を除外した配列を返す配列スキーマ。
 *
 * ここで言う空要素とは
 * - 空文字要素 `''`
 * - undefined要素 `undefined`
 * - null要素 `null`
 * - 空配列要素 `[]`
 *
 * の4つ。\
 * 空配列要素は、対象の配列そのものが空ということではなく、配列の小要素として空配列がある場合に除外するという意味。
 *
 * @param zodType
 * @returns
 */
export const excludeEmptyArray = <T extends z.ZodTypeAny>(zodType: T) =>
  preprocess(
    (arg) => {
      if (Array.isArray(arg)) {
        // 有効値がセットされたもののみに絞る。
        // preprocessで配列を切り詰めると、切り詰めたあとバリデーションされるため、エラー発生時にpathのindexが狂う問題がある。
        // transformで切り詰める
        return arg.filter((v) => v !== undefined && v !== null && v !== '');
      }
      return arg;
    },
    z.array(zodType).transform((arg) => arg.filter((v) => !(Array.isArray(v) && v.length === 0))),
  ) as unknown as z.ZodEffects<z.ZodArray<T, 'many'>>;

type ZodIntersectionAll<T extends [z.ZodTypeAny, ...z.ZodTypeAny[]]> = T extends [infer U]
  ? U
  : T extends [infer A extends z.ZodTypeAny, ...[infer B extends z.ZodTypeAny, ...infer C extends z.ZodTypeAny[]]]
    ? ZodIntersectionAll<[z.ZodIntersection<A, B>, ...C]>
    : T[0];

/**
 * zodにintersectionAllがないためのヘルパー。
 *
 * z.unionは任意数のunionが可能なのにz.intersectionは２引数しか取らないため、3つ以上もまとめられるようにした。
 * x.and(y).and(z).......とかって可読性が悪いため。
 *
 * @param schemas 一つ以上、上限任意数のzod schemaからなるタプル。
 * @returns 引数で渡されたschemaをintersectionした結果。
 */
export const intersectionAll = <T extends [z.ZodTypeAny, ...z.ZodTypeAny[]]>(schemas: T): ZodIntersectionAll<T> => {
  const [v0, ...vRest] = schemas;
  // 与えられた配列引数をandで数珠つなぎにする。
  return vRest.reduce((sum, current) => sum.and(current), v0) as unknown as any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

/**
 * 関数型の交差型を作る。
 *
 * zodのandとかintersectionは関数に対応してない。Typescript的には対応してるから、型定義的には正しくintersectionできるけど、
 * zodで関数をintersectionしようとするとフツーに腐る。
 * 幸い、自分で実装できるレベルなので・・・
 *
 * @param schemas
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const zodFunctionIntersectionAll = <T extends [z.ZodFunction<any, any>, ...z.ZodFunction<any, any>[]]>(
  schemas: T,
): ZodIntersectionAll<T> => {
  /** validな戻り値型のスキーマリスト。 */
  let validReturnTypes: [z.ZodTypeAny, ...z.ZodTypeAny[]] | undefined;

  return z.function(
    // 引数型のバリデーションルール。引数型のバイデーションはそんなにちゃんと作る必要ないんだけどね。
    (z.unknown() as unknown as Exclude<z.AnyZodTuple, null>).superRefine((arg, ctx) => {
      const argIssues: z.ZodIssue[] = [];
      (schemas as unknown as z.ZodFunction<z.AnyZodTuple, z.ZodUnknown>[]).forEach((s) => {
        const parsed = s.parameters().safeParse(arg);
        if (parsed.success) {
          // 引数型が正しいなら、その引数型に対応する戻り値型のスキーマをvalidReturnTypesに登録する。
          if (validReturnTypes) {
            validReturnTypes.push(s.returnType());
          } else {
            validReturnTypes = [s.returnType()];
          }
        }
      });

      if (validReturnTypes) {
        // チェックにパスしてるのなら、引数をそのまま採用する。
        return arg;
      }

      // 引数のチェックにパスしないのであれば、issueを登録してエラーとする。
      // ここで例外が発生すると関数の呼び出しが行われない
      argIssues.forEach((i) => {
        ctx.addIssue(i);
      });
      return z.NEVER;
    }) as unknown as z.AnyZodTuple,

    // 戻り値型のバリデーションルール。これをやっとくと、戻り値型アリのタイプのコールバック実装に関するミスを指摘できる。
    z.unknown().superRefine((arg, ctx) => {
      // 関数の交差型では、戻り値型はand条件で全て満たす必要がある。
      // 「リスコフの置換原則」、「戻り値共変」、「引数反変」とかその辺の単語でググって。
      const result = intersectionAll(validReturnTypes ?? [z.any()]).safeParse(arg);

      if (result.success) {
        return arg;
      }
      result.error.issues.forEach((i) => {
        ctx.addIssue(i);
      });
      return z.NEVER;
    }),
  ) as unknown as ZodIntersectionAll<T>;
};

/**
 * 対象のZod型がOptionalでなければOptionalにする。
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type WrapZodOptional<T extends z.ZodTypeAny> = T extends z.ZodOptional<any> ? T : z.ZodOptional<T>;

/**
 * 値非必須（空入力の許容。）
 *
 * この表の - に当たるやつ。optionalだけど、空文字とかnullもOK
 * https://st-integration-manual-atone.np-atobarai.info/check-specifications
 *
 * - 値的には空入力を許容し、undefinedに寄せる
 * - 型定義的にはoptionalという扱いにする（型定義の方が厳しいってこと）
 *
 * tsの型定義的にはoptionalになる。
 * しかし、ロジック的には、NaN, undefined, null, ''を許容する
 * @param type
 */
export const allowEmpty = <T extends z.ZodTypeAny>(
  type: T,
): z.ZodEffects<WrapZodOptional<T>, WrapZodOptional<T>['_output'], WrapZodOptional<T>['_input']> =>
  z.preprocess(
    (arg, _ctx) => {
      // 空入力はundefinedにしちゃう。
      if (arg === undefined || arg === null || arg === '' || Number.isNaN(arg)) {
        return undefined;
      }
      return arg;
    },
    // 対象がoptionalでなければoptionalにする。
    type.isOptional() ? type : type.optional(),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) as unknown as any;

export const allowEmptyInputWithDefault = <T extends z.ZodTypeAny>(
  type: T,
  defaultValue: unknown = undefined,
): z.ZodEffects<T, T['_output'], T['_input'] | undefined> =>
  z.preprocess(
    (arg, _ctx) => {
      // 空入力はundefinedにしちゃう。
      if (arg === undefined || arg === null || arg === '' || Number.isNaN(arg)) {
        return typeof defaultValue === 'function' ? defaultValue() : defaultValue;
      }
      return arg;
    },
    type,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) as unknown as any;
