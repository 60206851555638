// TODO: 共通ライブラリにそのまま移せるはず。
/* eslint-disable max-classes-per-file */
import { z } from 'zod';
import { indent, zodErrorToMessage } from '@berlin-front/common/utils';
import { callbackRequestDataSchema } from '@berlin-front/common/schemas/callback-base-schema';

/*
モーダルの表示プロセス

- createModalElement
  - モーダルの要素を作成し、bodyに配置する。。
  - この時点でのurlはabout:blank
  
- initialize
  - 何度でも呼べる。呼ぶと完全に初期化される。
  - パラメータの更新（atone.merge等）を行う時は基本、これで全部やり直す形にする。
  - ここでモーダルにURLを与える
  - サービス別の初期化パラメータを連携する。
    - この時点でshopOptionのような事前処理が行われる。
- activateModal
  - モーダルを有効化。ただしまだ表示はされず、この時点ではメインページについては操作不能になる。
- sendStart
  - サービス種別を選択し、決済なり認証なりを開始する。
- showModal
  - モーダルからのメッセージにより、表示する。
  - モーダルからメッセージが来ない場合は表示されることはない（NP後払い）。
- hideModal
  - 非表示にする。非表示化の主体はモーダル側。
- deactivateModal
  - モーダルを無効化。メインページが操作できるようになる。
  - モーダルが閉じた時に実行されるべきもの。

- destroyModalElement
  - 配置したmodalのdom要素を破棄します。
  - SPAなどで離脱時にはこれを行うことでいい感じにクリアするようにします。

*/

/**
 * デフォルトのz-index
 */
const MODAL_Z_INDEX = 9999;

// オーバレイのベースカラーを#000000、shopサイトの背景を#ffffffと仮定したとき、
// オーバレイが表示された領域のカラーが#a7a7a7なる透過度を求めている。#a7a7a7はfigmaにおけるオーバレイの背景表示から。
const MODAL_OVERLAY_OPACITY = 1 - 0xa7a7a7 / 0xffffff;

const IFRAME_STYLE: Partial<CSSStyleDeclaration> = {
  position: 'relative',
  border: '0px; top: 0px; left: 0px',
  height: '100%',
  width: '100%',
  display: 'block',
  opacity: '1',
  overscrollBehavior: 'none',
};

const MODAL_CONTAINER_VISIBLE_STYLE: Partial<CSSStyleDeclaration> = {
  display: 'block',
  // zIndex: `${MODAL_Z_INDEX}`,
  position: 'fixed',
  height: '100%',
  width: '100%',
  top: '0px',
  left: '0px',
  opacity: '1',
  backgroundColor: `rgba(0, 0, 0, ${MODAL_OVERLAY_OPACITY})`,
  transition: 'transform 0.3s ease-out 0s, opacity 0.3s ease-out 0s',
  overscrollBehavior: 'none',
  margin: '0px',
  boxSizing: 'border-box',
  overflow: 'hidden',
  backdropFilter: 'blur(5px)',
  '-webkit-backdrop-filter': 'blur(5px)',
  transform: 'scale(1)',
  transformOrigin: 'center center',
};
const MODAL_CONTAINER_HIDDEN_STYLE: Partial<CSSStyleDeclaration> = {
  ...MODAL_CONTAINER_VISIBLE_STYLE,
  transform: 'scale(1.1)',
  opacity: '0',
};
const MODAL_CONTAINER_CLOSED_STYLE: Partial<CSSStyleDeclaration> = {
  ...MODAL_CONTAINER_HIDDEN_STYLE,
  display: 'none',
};

const modalCommonOptionSchema = z.object({
  z_index: z.coerce.number().int().default(MODAL_Z_INDEX),
});

type ModalCommonOption = { service_type: string; modal_mode?: string; option: typeof modalCommonOptionSchema._output };

let modalCommonOptions: ModalCommonOption[] = [];

type Service = {
  service_type: string;
  modal_mode?: string;
} & { [K in string]: unknown };

/**
 * モーダルが取りうる状態定義。
 */
const MODAL_STATE = {
  /**
   * 未配置
   *
   * DOMに配置されていない、まっさらな状態。
   */
  NONE: 'NONE',

  /**
   * 配置済み実実行
   *
   * ASSIGNEDからINIT要求を実行するとこの状態になる。
   * startの実行ができるのはこの状態の時。
   */
  IDLE: 'IDLE',

  /**
   * 実行中
   *
   * IDLE状態からSTART要求を行うとこの状態になる。
   */
  OPENED: 'OPENED',
} as const;

const env = {
  MODULE_URL: '',
  MODULE_ORIGIN: '',
};

type ModalState = (typeof MODAL_STATE)[keyof typeof MODAL_STATE];

/**
 * 実行しているサービスを決定づける情報。
 */
type ServiceID = {
  /**
   * サービスタイプ（atone翌月後払い/NP後払い/atoneつど払い）
   */
  service_type: string;
  /**
   * モーダルモード（認証/決済）
   */
  modal_mode?: string;
};

let asyncTaskAbortController = new AbortController();

/**
 * モーダルのラッパー。
 */
let container: HTMLDivElement | undefined;

/**
 * モーダル本体
 */
let iframe: HTMLIFrameElement | undefined;

/**
 * モーダルへ送信するコマンドを受け付けるポート。
 */
let commandRequestPort: MessagePort | undefined;

/**
 * モーダルからのコールバック実行要求メッセージを受け付けるポート
 */
let modalMessageReceiverPort: MessagePort | undefined;

/**
 * コールバック。
 */
let callbacks: ({ service_type: string; modal_mode?: string } & { [K in string]: (...args: unknown[]) => unknown })[] =
  [];

/**
 * モーダルの状態管理と、状態に応じたタスクの逐次実行を制御する。
 *
 * シングルトンクラスにしてる。
 */
const modalStateManager = new (class ModalStateManager {
  private modalState: ModalState = MODAL_STATE.NONE;

  private tasks: {
    states: ModalState[];
    task: () => void;
  }[] = [];

  private runTask() {
    while (this.tasks[0] && this.tasks[0].states.includes(this.modalState)) {
      this.tasks.shift()?.task();
    }
  }

  clearTasks() {
    this.tasks.splice(0);
  }

  queueTask(task: { states: ModalState[]; task: () => void }): void {
    this.tasks.push(task);
    this.runTask();
  }

  get value(): ModalState {
    return this.modalState;
  }

  set value(state: ModalState) {
    this.modalState = state;
    this.runTask();
  }
})();

/**
 * 複数のasyncタスクが後勝ちで、古いタスクをキャンセルする仕組み。
 */
const cancelConcurrentAsyncTask = () => {
  // 待ち受けてるタスクを殺す。
  modalStateManager.clearTasks();
  asyncTaskAbortController.abort();
  return [
    new Promise((_, reject) => {
      asyncTaskAbortController = new AbortController();
      asyncTaskAbortController.signal.addEventListener('abort', reject);
    }),
    asyncTaskAbortController.signal,
  ] as const;
};

/**
 * 現在選択されているサービス。サービスパラメータから現在のサービスに対応したparameterを辞引きするのに使用する。
 */
let currentService: ServiceID | undefined;

/**
 * 指定したHTMLElementにstyle指定したstyleを適用します。
 *
 * @param element
 * @param style
 * @returns
 */
const setStyle = (element: HTMLElement, style: Partial<CSSStyleDeclaration>) => {
  const oldStyle = Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Object.keys(style).map((key) => [key, (element.style as Record<string, any>)[key]]),
  );
  const restore = () => Object.assign(element.style, oldStyle);
  Object.assign(element.style, style);
  return restore;
};

/**
 * モーダルコンテナのスタイルを管理するコンポーネント。
 */
const containerStyleManager = {
  /* eslint-disable no-underscore-dangle */
  _container: undefined as HTMLDivElement | undefined,
  _style: {} as Partial<CSSStyleDeclaration>,
  set container(elem: HTMLDivElement | undefined) {
    if (elem) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      elem.clientHeight;
      // 初期スタイル
      this._style = { ...MODAL_CONTAINER_CLOSED_STYLE };
      setStyle(elem, this._style);
      document.body.appendChild(elem);
    }
    this._container = elem;
  },
  get container() {
    return this._container;
  },
  setStyle(param: Partial<CSSStyleDeclaration>) {
    // 変更なければ何もしない。
    if (Object.entries(param).every(([k, v]) => this._style[k as keyof CSSStyleDeclaration] === v)) return;
    Object.assign(this._style, param);
    if (this._container) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      this._container.clientHeight;
      setStyle(this._container, this._style);
    }
  },
  /* eslint-enable no-underscore-dangle */
};

const { setDeviceScale, clearDeviceScale } = (() => {
  const isMobile = ['iPhone', 'Android', 'mobile'].some(
    (fragment) => window.navigator.userAgent.indexOf(fragment) !== -1,
  );
  const hasViewportSetting = Array.from(window.document?.head?.childNodes ?? []).some(
    (node) => node instanceof HTMLMetaElement && node.name === 'viewport',
  );
  if (isMobile && !hasViewportSetting) {
    // <meta name="viewport" />をheadにブッコする。
    const additionalViewport = document.createElement('meta');
    additionalViewport.name = 'viewport';
    window.document.head.appendChild(additionalViewport);
    return {
      /**
       * モーダルの表示に適したdevice-scale設定を行う。
       */
      setDeviceScale: () => {
        additionalViewport.content =
          'width=device-width,minimum-scale=1.0,maximum-scale=1.0,initial-scale=1.0,user-scalable=no';
      },
      /**
       * 設定したdevice-scaleをクリアする。
       */
      clearDeviceScale: () => {
        // safariとかいうクソブラウザのバグで、metaタグ抜いても設定が無効にならない。
        // 無効にするにはcontentを空にする必要がある。
        additionalViewport.content = '';
      },
    };
  }
  // モバイルではない場合、もしくは会員サイト側がviewport設定を行なっている場合は何もしない。
  return { setDeviceScale: () => {}, clearDeviceScale: () => {} };
})();

/**
 * 指定した要素のトランジションが終了するのを待つ。
 * @param element
 */
const waitTransitionEnd = async (element: HTMLElement) => {
  let removeAllListener = () => {};
  const promise = new Promise<Event>((resolve, reject) => {
    // トランジションイベントが何も発火しなくても、1秒経ったら解決したとみなす。
    // おそらく問題ないけどうまくイベントが発生しない場合の保険。
    const timerId = window.setTimeout(resolve, 1000);
    // transitionendが発火したら成功とする。
    element.addEventListener('transitionend', resolve);
    // transitioncancelが発火した場合は失敗とする。
    element.addEventListener('transitioncancel', reject);
    // イベントハンドラの解除はfinallyで実行する。
    removeAllListener = () => {
      window.clearTimeout(timerId);
      element.removeEventListener('transitionend', resolve);
      element.removeEventListener('transitioncancel', reject);
    };
  });
  void promise.finally(removeAllListener);
  return promise;
};

const containerVisibilityController = new (class {
  private state: 'VISIBLE' | 'INVISIBLE' | 'SHOWING' | 'HIDING' = 'INVISIBLE';

  async hide() {
    if (!container) return;

    containerStyleManager.setStyle(MODAL_CONTAINER_HIDDEN_STYLE);
    if (this.state !== 'INVISIBLE') {
      this.state = 'HIDING';
      try {
        await waitTransitionEnd(container);
      } catch {
        // showによってanimationがキャンセルされた場合、stateの変更は行なわずに離脱する。
        return;
      }
    }
    this.state = 'INVISIBLE';
    clearDeviceScale();
  }

  async show() {
    if (!container) return;

    setDeviceScale();

    const s = currentService;
    if (currentService) {
      const option = modalCommonOptions.find(
        (v) => v.service_type === s?.service_type && (v.modal_mode ?? '01') === (s?.modal_mode ?? '01'),
      )?.option;
      if (option) {
        containerStyleManager.setStyle({ zIndex: `${option.z_index}` });
      }
    }

    containerStyleManager.setStyle(MODAL_CONTAINER_VISIBLE_STYLE);
    if (this.state !== 'VISIBLE') {
      this.state = 'SHOWING';
      try {
        await waitTransitionEnd(container);
      } catch {
        // hideによってanimationがキャンセルされた場合、stateの変更は行なわずに離脱する。
        return;
      }
    }
    this.state = 'VISIBLE';
    await waitTransitionEnd(container);
  }
})();

/**
 * メインページに対するロックを解放します。
 */
let unlockMainPage = () => {};

/**
 * モーダルから受け取ったメッセージの処理。
 */
const onMessageFromService = (ev: MessageEvent) => {
  void (async () => {
    if (!modalMessageReceiverPort) return;
    const parseResult = callbackRequestDataSchema.safeParse(ev.data);
    if (!parseResult.success) return;
    const { data } = parseResult;

    const returnMessagePort = modalMessageReceiverPort;
    const successResponseBase = { messageId: data.messageId, type: data.type, success: true };
    const errorResponseBase = { messageId: data.messageId, type: data.type, success: false };

    // ビルトインコールバック。
    // モーダルの表示制御、ライフサイクル制御をおこなう。
    // 開始のみ、start実行時にModalDriver側でタイミングを管理する。
    switch (data.type) {
      // 表示<->非表示切り替えの共通処理。これはサービスタイプに関わらない共通メッセージ。
      case 'show': {
        await containerVisibilityController.show();
        returnMessagePort.postMessage(successResponseBase);
        break;
      }
      // 表示<->非表示切り替えの共通処理。これはサービスタイプに関わらない共通メッセージ。
      // 多分hideは使われないと思うけど。
      case 'hide': {
        await containerVisibilityController.hide();
        returnMessagePort.postMessage(successResponseBase);
        break;
      }
      // モーダル側で全処理が終了した場合の通知。domを非表示にして、操作を回復する。
      case 'close': {
        await containerVisibilityController.hide();
        unlockMainPage();
        returnMessagePort.postMessage(successResponseBase);
        // 閉じ終わったらIDLE状態にする。これで他の他のサービスの開始と保留されていた再初期化命令の受付ができる。
        modalStateManager.value = MODAL_STATE.IDLE;
        currentService = undefined;
        break;
      }
      default: {
        // ビルトインコールバックがなければ、サービス毎に設定されたコールバックを探して実行する。
        const targetCallbackService = data.service ?? currentService;
        if (targetCallbackService) {
          const callbackFunctionName = data.type.toLowerCase();
          // なんらかのサービスが起動中なら対応するコールバックを取得する。
          const { [callbackFunctionName]: callback } = callbacks.find(
            (service) =>
              service.service_type === targetCallbackService.service_type &&
              (service.modal_mode ?? '01') === (targetCallbackService?.modal_mode ?? '01'),
          ) ?? { [callbackFunctionName]: () => {} };
          if (typeof callback === 'function') {
            // 登録されたコールバックの実行。payloadを配列データにすると、配列の要素が展開されて、複数の引数を渡すことができる。
            try {
              const rawCallbackResult = callback(...(data.payload ?? []));
              // コールバックの戻り値がPromise型の場合。
              // PromiseについてはNp側はtranspiler通してるし、クライアント側もtranspiler通してる可能性がある。
              // コード上は同じPromise型でも、双方で異なる型になってる可能性があるためNp側に合わせる必要がある。
              // そこで、Np側のPromiseでラップしてあげる。
              let callbackResult = rawCallbackResult;

              let timer = NaN;
              if (
                // thenとcatch関数を持つオブジェクトだった場合promiseとみなす。
                rawCallbackResult &&
                rawCallbackResult !== null &&
                typeof rawCallbackResult === 'object' &&
                'then' in rawCallbackResult &&
                typeof rawCallbackResult.then === 'function' &&
                'catch' in rawCallbackResult &&
                typeof rawCallbackResult.catch === 'function'
              ) {
                callbackResult = await new Promise<unknown>((resolve, reject) => {
                  (rawCallbackResult.then as (args: unknown) => void)(resolve);
                  (rawCallbackResult.catch as (args: unknown) => void)(reject);
                  // あまりないと思うけど1分以内にコールバックの結果が帰らないならエラーとして扱ってしまう。ずーっとホールドするわけにはいかないだろうから。
                  // 今の所pre_paymentくらいでしか非同期で戻り値を返すコールバックを呼ぶことはないだろうし、
                  // 既存のpre_paymentつかってる加盟店はコールバックとして同期関数使ってるので、実質、本番でこのコードは呼ばれないと思う。
                  timer = window.setTimeout(
                    () => reject(new Error(`非同期コールバックが60秒間以上結果を返却しませんでした。`)),
                    60 * 1000,
                  );
                });
              }
              window.clearTimeout(timer);
              if (returnMessagePort === modalMessageReceiverPort) {
                // モーダルが再起動されてなければ、コールバックの実行結果をモーダル側に返す。
                // モーダル側から応答が求められるのは現状ではpre_paymentコールバックだけだけど、
                // 基本的に全てのコールバックで結果を返すようにしている。
                returnMessagePort.postMessage({
                  ...successResponseBase,
                  payload: callbackResult,
                });
              }
            } catch (e) {
              let name = 'Error';
              const args = indent(JSON.stringify(data.payload ?? [], null, 2), 2);
              const message = [`コールバック: ${data.type} でエラーが発生。`, `引数:`, args, `エラー詳細:`];
              let stack: string | undefined;

              if (e !== null && typeof e === 'object') {
                if (e instanceof z.ZodError) {
                  message.push(indent(zodErrorToMessage(e), 2));
                  name = e.name;
                  stack = e.stack;
                } else if (e instanceof Error) {
                  message.push(e.message);
                  name = e.name;
                  stack = e.stack;
                } else {
                  try {
                    message.push(indent(JSON.stringify(e, null, 2), 2));
                  } catch {
                    message.push(indent(String(e), 2));
                  }
                }
              }
              returnMessagePort.postMessage({
                ...errorResponseBase,
                error: { name, message: message.join('\n'), stack },
              });
            }
          }
        }
      }
    }
  })();
};

/**
 * modalのelementを生成し、配置します。
 */
const createModalElement = () => {
  if (container) {
    document.body?.removeChild(container);
  }

  // modalのelementを生成する。
  container = document.createElement('div');
  iframe = document.createElement('iframe');
  iframe.allow = 'otp-credentials';
  iframe.name = 'modal';
  iframe.setAttribute('scroll', 'auto');
  iframe.src = env.MODULE_URL;
  setStyle(iframe, IFRAME_STYLE);
  container.appendChild(iframe);
};

const closeConnectionHandlers: (() => void)[] = [];
const closeConnection = () => {
  closeConnectionHandlers.splice(0).forEach((v) => v());

  modalMessageReceiverPort?.removeEventListener('message', onMessageFromService);
  modalMessageReceiverPort?.close();
  commandRequestPort?.close();
  modalMessageReceiverPort = undefined;
  commandRequestPort = undefined;
};

const loadModal = () => {
  let retry = 0;
  let connected = false;

  let onLoad = () => {};

  const requestChannel = new MessageChannel();
  const receiverChannel = new MessageChannel();

  // 大元のportは同期的に作るので、接続を待たずにpostMessageできる。
  commandRequestPort = requestChannel.port1;
  modalMessageReceiverPort = receiverChannel.port1;

  // この時点でもう実行できる。
  modalStateManager.value = MODAL_STATE.IDLE;

  const loadIframe = async () => {
    while (!connected && retry < 5) {
      const connectionFailedHandlers: (() => void)[] = [];
      try {
        createModalElement();
        // eslint-disable-next-line @typescript-eslint/no-loop-func, no-await-in-loop
        await new Promise<void>((resolve, reject) => {
          // iframeに対するロードイベントハンドラを仕掛ける。
          if (!iframe) {
            reject();
            return;
          }
          const targetIframe = iframe;
          onLoad = () => {
            if (targetIframe.contentWindow) {
              // リレー用のchannelとポート。接続失敗した場合は作り直す。ポートは多段接続する。
              // ポートをiframeに送りつけた後、接続確立しなかった場合、ポートの再利用はできないようなことがMDNに書いてあったので中継するように実装してる。
              const relayReceiverChannel = new MessageChannel();
              connectionFailedHandlers.push(
                () => relayReceiverChannel?.port1.close(),
                () => relayReceiverChannel?.port2.close(),
              );
              /** INIT, STARTなどのコマンド送信用のみに使用するchannel。 */
              const relayRequestChannel = new MessageChannel();
              connectionFailedHandlers.push(
                () => relayRequestChannel?.port1.close(),
                () => relayRequestChannel?.port2.close(),
              );
              targetIframe.contentWindow.postMessage({ type: 'BOOT' }, env.MODULE_ORIGIN, [
                relayRequestChannel.port2,
                relayReceiverChannel.port2,
              ]);

              // ロードイベント後、iframe上でアプリケーションが正常に動いていれば、即座にメッセージがくる。
              // timeoutを1秒としてるがそんなにかからないはず。
              const timer = window.setTimeout(reject, 10000);
              const onConnectionComplete = (_event: MessageEvent) => {
                window.clearTimeout(timer);
                // コールバック応答用の接続。
                const callbackResponseMessageRelayEventHandler = (ev: MessageEvent) => {
                  relayReceiverChannel.port1.postMessage(ev.data);
                };
                receiverChannel.port2.addEventListener('message', callbackResponseMessageRelayEventHandler);
                // コールバック受信用の接続
                const callbackRequestMessageReceiveEventHandler = (ev: MessageEvent) => {
                  receiverChannel.port2.postMessage(ev.data);
                };
                relayReceiverChannel.port1.addEventListener('message', callbackRequestMessageReceiveEventHandler);
                // relayReceiverChannel.port2.start(); // 応答受信はmodal側で開始済み。
                receiverChannel.port2.start(); // 応答開始
                receiverChannel.port1.start(); // 受信開始
                relayReceiverChannel.port1.start(); // 受信リレー開始

                // コマンド送信用の接続。
                const requestMessageEventHandler = (ev: MessageEvent) => {
                  relayRequestChannel.port1.postMessage(ev.data);
                };
                requestChannel.port2.addEventListener('message', requestMessageEventHandler);
                // relayRequestChannel.port2.start(); // モーダルのコマンド受信は既に開始してる。
                // relayRequestChannel.port1.start(); // 起動メッセージの受信はonConnectionComplete仕掛ける時に開始してる。
                requestChannel.port2.start();
                // requestChannel.port1.start(); コマンドは送信専用だから受信を開かなくて良い。
                resolve();
              };
              relayRequestChannel.port1.addEventListener('message', onConnectionComplete, { once: true });
              closeConnectionHandlers.push(() =>
                relayRequestChannel.port1.removeEventListener('message', onConnectionComplete),
              );
              relayRequestChannel.port1.start(); // 起動メッセージの受信を開始
            }
          };
          // ロードイベントは成否にかかわらず必ず発生するので。onceにしてたらfailedhandlerで解除する必要ないかも。
          targetIframe.addEventListener('load', onLoad, { once: true });
          connectionFailedHandlers.push(() => targetIframe.removeEventListener('load', onLoad));
          if (container) {
            containerStyleManager.container = container;
          }
        });
        // 接続完了
        connected = true;
        if (modalMessageReceiverPort) {
          const currentModalMessageReceiverPort = modalMessageReceiverPort;
          currentModalMessageReceiverPort.addEventListener('message', onMessageFromService);
          closeConnectionHandlers.push(() =>
            currentModalMessageReceiverPort.removeEventListener('message', onMessageFromService),
          );
        }
      } catch {
        retry += 1;
        connectionFailedHandlers.splice(0).forEach((v) => v());
      }
    }
  };

  if (document.readyState === 'loading') {
    // loading中はdomの読み込みんがまだなのでbodyにelementを配置できない。
    // loadingでなくなったら(interactiveでもcompleteでも)bodyはできてるのでモーダルの配置が可能。
    document.addEventListener(
      'readystatechange',
      () => {
        void loadIframe();
      },
      { once: true },
    );
  } else {
    // そうでなければ即実行
    void loadIframe();
  }
};

/**
 * モーダルを有効化します。
 *
 * この時点ではまだ、モーダルは表示されません。
 * これはNP後払いのように、実行中画面を描画しないタイプのモーダルに対応するためです。
 *
 * @returns
 */
const activateModal = () => {
  containerStyleManager.setStyle(MODAL_CONTAINER_HIDDEN_STYLE);

  // メインページに対する操作を止める。
  // スクロールロック
  const { scrollY } = window;
  const restoreStyle = setStyle(document.documentElement, {
    position: 'fixed',
    width: '100%',
    overscrollBehavior: 'none',
    top: `-${scrollY}px`,
  });

  /**
   * メイン画面のfocus中要素からblurさせる。
   *
   * @returns blurした要素にfocusを戻す関数。
   */
  const forceBlurMainPage = () => {
    let reFocus = () => {};
    const activeElem = window.document.activeElement;
    if (activeElem instanceof HTMLElement) {
      activeElem.blur();
      reFocus = () => activeElem.focus();
    }
    return reFocus;
  };
  window.addEventListener('focusin', forceBlurMainPage);
  const reFocus = forceBlurMainPage();

  // 復帰関数を設定
  unlockMainPage = () => {
    // フォーカス禁止からの復帰
    window.removeEventListener('focusin', forceBlurMainPage);
    reFocus();
    // スクロールロックからの復帰
    restoreStyle();
    window.scrollTo(window.scrollX, scrollY);
    // modal消す。
    if (container) {
      containerStyleManager.setStyle(MODAL_CONTAINER_CLOSED_STYLE);
    }
  };
};

/**
 *
 * @param services サービスごとのパラメータのうち、コールバックを取り除いた内容を設定する。
 * @returns
 */
const init = (services: Service[]) => {
  modalCommonOptions = services.flatMap((v) => {
    const parseResult = modalCommonOptionSchema.safeParse(v);
    return parseResult.success
      ? [
          {
            service_type: v.service_type,
            modal_mode: v.modal_mode,
            option: parseResult.data,
          } satisfies ModalCommonOption,
        ]
      : [];
  });
  // 受け取ったservicesからパラメータのみを分離する。
  const serviceParams = services.map(
    (service) => Object.fromEntries(Object.entries(service).filter(([_, v]) => typeof v !== 'function')) as Service,
  );

  // 同様にコールバックのみを分離する。
  const serviceCallbacks = services.map(
    (service) =>
      ({
        ...(Object.fromEntries(Object.entries(service).filter(([_, v]) => typeof v === 'function')) as {
          [K in string]: (...args: unknown[]) => unknown;
        }),
        service_type: service.service_type,
        ...(service.modal_mode ? { modal_mode: service.modal_mode } : {}),
      }) as (typeof callbacks)[number],
  );

  // loadModalが実行されてすらいない場合initする前に実行する。
  // ただ、本来loadModalは適切なタイミングで読んでおいてほしい。
  if (modalStateManager.value === MODAL_STATE.NONE) {
    loadModal();
  }

  // 初期化が実行できるタイミングまで待ち最後に受け取った初期化パラメータを使用して初期化を行う。
  modalStateManager.queueTask({
    states: [MODAL_STATE.IDLE],
    task: () => {
      // モーダルのリロードはしない。Atoneと一緒に使いたいとかあるので。パラメータは追記書き込みにしていく。
      // if (modalStateManager.value === MODAL_STATE.IDLE) {
      //   loadModal();
      // }
      if (!commandRequestPort) return;
      // コールバックを更新する。
      callbacks = serviceCallbacks;
      // コールバック以外をモーダルへ送信する。
      commandRequestPort.postMessage({
        type: 'INIT',
        payload: { services: serviceParams },
      });
    },
  });
};
const start = (serviceId: ServiceID) => {
  // 起動中は単に無視する。
  if (modalStateManager.value !== MODAL_STATE.IDLE) return;
  if (!commandRequestPort) return;

  activateModal();
  currentService = serviceId;
  commandRequestPort.postMessage({
    type: 'START',
    payload: serviceId,
  });
  modalStateManager.value = MODAL_STATE.OPENED;
};

/**
 * モーダルの要素を削除する。
 *
 * SPAとかで決済画面から離脱し、全部片付けたい時に使用する。
 * @returns
 */
const destroyModalElement = () => {
  modalStateManager.value = MODAL_STATE.NONE;
  void cancelConcurrentAsyncTask();
  closeConnection();
  currentService = undefined;
  callbacks = [];

  if (!container) return;
  document.body.removeChild(container);
  container = undefined;
  iframe = undefined;
};

type SetEnvParam = {
  /**
   * 環境ごとのモーダルリソースURLを指定する。
   */
  moduleUrl: string;
};
/**
 * 複数のサービス設定でモーダルの起動を受け付けるModalDriverです。
 */
const ModalDriver = {
  /**
   * 環境指定。
   *
   * production環境やstaging環境のorigin情報、urlを設定する。
   * ページロード後、1回だけ実行できる。
   */
  env(envParam: SetEnvParam) {
    if (!env.MODULE_URL) {
      Object.assign(env, {
        MODULE_URL: envParam.moduleUrl,
        MODULE_ORIGIN: envParam.moduleUrl.replace(/(^https?:\/\/[^/]*).*$/, '$1'),
      } satisfies typeof env);
    }
  },

  /**
   * モーダルを画面に配置します。
   *
   * @returns
   */
  loadModal() {
    void loadModal();
  },

  /**
   * パラメタを設定してモーダルを初期化します。
   *
   * - setEnvと異なり複数回呼ぶことができる。起動後のモーダルのパラメータを差し替える唯一の方法。
   * - 2回目以降の実行ではモーダルを再読みし、パラメータを渡し直す。
   * - モーダルが動いている間は処理が保留される。閉じたタイミングで動作する。
   */
  init(services: Service[]) {
    void init(services);
  },

  /**
   * モーダルを開始する。
   *
   * 開始後はstopで停止するまで、会員サイト側を操作できない。
   * 会員サイト側からモーダルを修了するには、destroyを呼ぶ必要がある。
   *
   * @param message
   */
  start(serviceId: ServiceID) {
    start(serviceId);
  },

  /**
   * 配置されたモーダルを削除します。
   *
   * 会員サイト側からモーダルを完全に消去するためにはこれを呼ぶ必要がある。
   * 基本的にSPAで決済画面から離脱する場合に使用することを想定している。
   */
  destroy() {
    destroyModalElement();
  },
};

export default ModalDriver;
