/* eslint-disable no-var */
import { DeepOmit } from 'deep-utility-types';
import type { AtoneSettlementServiceInputType } from '@schemas/atone-settlement/AtoneSettlementParameterSchema';
import { legacyAtoneSettlementCallbackDefinition } from '@schemas/atone-settlement/LegacyAtoneSettlementCallbackSchema';
import type commonParamAdditionalSpace from '@schemas/common-schemas/common-param-additional-space';
import ModalDriver from './ModalDriver';

type AdditionalSpaceParam = typeof commonParamAdditionalSpace._input;

type AtoneConfig = DeepOmit<
  AtoneSettlementServiceInputType,
  // 以下は固定なので入力できない。
  | 'is_legacy'
  | 'service_type'
  | 'modal_mode'
  // 以下は統合インターフェイスで名称が変更されたもの。動作的には新版も受け付けられるが、マニュアルのインターフェイスと合わせるために新版は型定義から除外する。
  | 'payment.description' // <- 旧Atoneでは 'description_trans' であるため除外。
  | 'payment.service_supplier.supplier_user_id' // <- 旧Atoneでは 'shop_customer_id' であるため除外。
> &
  typeof legacyAtoneSettlementCallbackDefinition.schema._input &
  AdditionalSpaceParam;

/**
 * Atone.configに渡された最新のパラメータを保持する。
 */
let rawConfig: AtoneConfig | undefined;

const Atone = {
  /**
   * 決済パラメータをセットし、決済モーダルの起動準備を行います。
   *
   * @param config 決済パラメータ
   * @param callback Deprecated: config終了後に実行したい関数がある場合セットします。exampleを参照すること。
   *
   * @example
   *   // 第二引数のコールバックはconfigとstartを一度の関数で実行するための仕掛けとして過去に用意されていたもの。
   *   // 以下のようにすることで、config実行後そのままstartさせることができる。
   *   Atone.config(param, () => Atone.start())
   *
   *   // しかし、configは同期的に動作するため、コールバッでstartするのではなく、以下のように書けば済む。
   *   // そのため、現在は第二引数はdeprecatedである。
   *   Atone.config(param);
   *   Atone.start();
   */
  config: (config: AtoneConfig, callback?: () => void): void => {
    rawConfig = config;
    // configをコールバックと値に分ける。
    /** パラメータ中のコールバック */
    const callbacks = Object.fromEntries(
      Object.entries(config).flatMap(([k, v]) => (typeof v === 'function' ? [[k, v]] : [])),
    );
    /** パラメータ中のコールバック以外のもの。 */
    const parameters = Object.fromEntries(
      Object.entries(config).flatMap(([k, v]) => (typeof v !== 'function' ? [[k, v]] : [])),
    );
    const wrappedCallback = legacyAtoneSettlementCallbackDefinition.schema.parse(callbacks);

    ModalDriver.init([
      {
        ...parameters,
        ...wrappedCallback,
        // 固定パラメータ。固定パラメータを末尾に配置するのは上書きされないように。
        service_type: '01',
        modal_mode: '01',
        is_legacy: true,
      },
    ]);
    if (callback) {
      console.warn('第二引数は非推奨です。');
      callback();
    }
  },
  /**
   * Atone翌月後払いの決済を開始します。
   */
  start: (): void => {
    ModalDriver.start({ service_type: '01', modal_mode: '01' });
  },
  /**
   * すでに設定済みのConfigに、引数を浅いマージします。
   * @deprecated 過去バージョンとの互換性のために残している。Atone.configを重複して呼ぶことができ、そちらの方が自由度が高いため、基本的にそちらを使用すること。
   */
  merge: (partialConfig: Partial<AtoneConfig>): void => {
    if (rawConfig) {
      Atone.config({ ...rawConfig, ...partialConfig });
    }
  },
  /**
   * @deprecated マージ結果などをモーダルに送信するタイミングはModalDriverが適切に判定するため、syncを呼ぶ必要はないし、現在、このメソッドは何もしない。
   */
  sync: (): void => {},
  /**
   * モーダルのロードが完了したかチェックするためのメソッドです。
   *
   * クライアントはこれを確認してからモーダルに対しコマンドを送信する仕様でしたが、現在、モーダルに対する各種操作は、ドライバー側でロードが完了するまで保留されるため\
   * このメソッドを使用する意義はありません。このメソッドは常にtrueを返します。
   * @deprecated
   */
  loaded: (): true => true,
  /**
   * 最後にセットされたconfigパラメータを返します。
   *
   * これはmergeによる修正も含みます。
   * @deprecated
   */
  properties: (): AtoneConfig | undefined => rawConfig,
} as const;

export default Atone;
